#oq-logout {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
}

#oq-logout i {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
}

#oq-logout i svg {
  width: 100%;
  height: 100%;
}

#oq-time,
#oq-day,
#oq-business-name {
  font-size: 16px;
  margin-left: 10px;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.7);
}

#oq-image {
  height: 35px;
  margin-left: 10px;
  margin-right: 10px;
}

#oq-username {
  font-size: 16px;
  margin-left: 10px;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.7);
}

.oq-dash-wrapper {
  position: fixed;
  display: flex;
  display: -webkit-flex;
  width: 100%;
  height: 100%;
}

.oq-dash-wrapper .oq-sidebar-area {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  width: 300px;
  height: 100%;
  overflow-x: hidden;
  z-index: 1000;
  background-color: #272933;
}

.oq-dash-wrapper .oq-sidebar-area::-webkit-scrollbar {
  width: 0;
}

.oq-dash-wrapper .oq-content-area {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  width: calc(100% - 300px);
  height: 100%;
  z-index: 999;
}

.oq-dash-wrapper .oq-content-area .oq-content-work {
  height: calc(100% - 68px);
  overflow-x: hidden;
  background-color: #fff !important;
}

.oq-dash-wrapper .oq-content-area .oq-content-work::-webkit-scrollbar {
  width: 10px;
}

.oq-dash-wrapper .oq-content-area .oq-content-work::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.oq-dash-wrapper .oq-navbar-wrapper {
  display: flex;
  display: -webkit-flex;
  height: 68px;
  width: 100%;
  background-color: #fff !important;
  z-index: 999;
}

.oq-dash-wrapper .oq-navbar-wrapper .content-left {
  display: flex;
  display: -webkit-flex;
  width: 100%;
  align-items: center;
  padding-left: 20px;
}

.oq-dash-wrapper .oq-navbar-wrapper .content-center {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.oq-dash-wrapper .oq-navbar-wrapper .content-right {
  display: flex;
  display: -webkit-flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-right: 20px;
  width: 100%;
}

.oq-z-depth-3 {
  box-shadow: 3px 0 30px rgba(0, 0, 0, 0.1);
}

.oq-z-depth-2 {
  box-shadow: 3px 0 15px rgba(0, 0, 0, 0.1);
}

.oq-z-depth-1 {
  box-shadow: 3px 0 10px rgba(0, 0, 0, 0.1);
}

.oq-dash-wrapper .oq-sidebar-menu {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.oq-dash-wrapper .oq-sidebar-menu li {
  transition: 0.3s;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
}

.oq-dash-wrapper .oq-sidebar-menu li.active {
  background-color: rgba(0, 0, 0, 0.3);
}

.oq-dash-wrapper .oq-sidebar-menu li:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.oq-dash-wrapper .oq-sidebar-menu li a {
  cursor: pointer;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  height: 100%;
  font-size: 14px;
  text-decoration: none;
}

.oq-dash-wrapper .oq-sidebar-menu li a .content-left {
  display: flex;
  display: -webkit-flex;
  width: 80%;
}

.oq-dash-wrapper .oq-sidebar-menu li a .content-right {
  display: flex;
  display: -webkit-flex;
  flex-direction: row-reverse;
  width: 20%;
}

.oq-dash-wrapper .oq-sidebar-menu li a .content-right svg {
  width: 25px;
  height: 25px;
}

.oq-dash-wrapper .oq-sidebar-menu li a i {
  margin-right: 20px;
  width: 20px;
  height: 20px;
}

.oq-dash-wrapper .oq-sidebar-menu li a i svg {
  width: 100%;
  height: 100%;
}

.oq-dash-wrapper .oq-sidebar-menu .oq-collapsible-content {
  background-color: rgba(0, 0, 0, 0.3);
}

.oq-dash-wrapper .oq-sidebar-menu .oq-collapsible-content-parent {
  height: 0;
  overflow: hidden;
  transition: height ease 0.3s;
}

.oq-dash-wrapper .oq-sidebar-menu .oq-collapsible-content ul {
  margin: 0;
  padding: 0;
}

.oq-dash-wrapper .oq-sidebar-menu .oq-collapsible-content ul li.active {
  background-color: rgba(255, 255, 255, 0.2);
}

.oq-dash-wrapper .oq-sidebar-menu .oq-collapsible-content ul li a {
  font-size: 14px !important;
}

.oq-dash-wrapper .oq-sidebar-menu .oq-sidebar-footer {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.oq-dash-wrapper .oq-sidebar-menu .oq-sidebar-footer:hover {
  background-color: transparent;
}

.oq-sidebar-menu .oq-sidebar-footer img {
  /* width: 40px; */
  height: 47px;
  margin-bottom: 10px;
}

.oq-dash-wrapper .oq-sidebar-menu .oq-sidebar-footer span {
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}

.oq-dash-wrapper .oq-sidebar-menu .oq-sidebar-footer a {
  display: initial;
  color: rgba(255, 255, 255, 0.5);
}

.oq-dash-wrapper .oq-sidebar-menu .oq-sidebar-footer a:hover {
  color: rgba(255, 255, 255, 0.7);
}

.oq-dash-wrapper .oq-sidebar-business-info {
  display: flex;
  display: -webkit-flex;
  background-color: rgba(255, 255, 255, 0.05);
  margin: 20px;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  min-height: 85px;
}

.oq-dash-wrapper .oq-sidebar-business-info .content {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
}

.oq-dash-wrapper .oq-sidebar-business-info .content a {
  margin-top: 10px;
  font-size: 12px !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

.oq-dash-wrapper .oq-sidebar-business-info .content a i {
  width: 16px !important;
  height: 16px !important;
  margin-left: 0 !important;
}

.oq-dash-wrapper .oq-sidebar-business-info .content #company-name {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.oq-dash-wrapper .oq-sidebar-business-info .content #company-email {
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
}

.oq-dash-wrapper .oq-sidebar-business-info img {
  max-width: 60px;
  margin-right: 10px;
  border-radius: 10px;
}

.oq-dash-wrapper input.required {
  background-color: rgba(255, 0, 0, 0.01);
  border: 2px solid rgba(255, 0, 0, 0.5);
}
