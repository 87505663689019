#oq-header-login {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

#oq-header-login h2 {
    margin-bottom: 0;
}

#oq-header-login img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 3px;
}

.oq-login {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgb(44, 51, 65);
    /* background-image: url("https://images.unsplash.com/photo-1573164574144-649081e9421a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"); */
    /* background-image: url("https://images.unsplash.com/photo-1616588181775-138dc8ba4197?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"); */
    /* background-image: url("https://images.unsplash.com/photo-1551989745-347c28b620e5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"); */
    /* background-image: url("https://wallpaperaccess.com/full/1191824.jpg"); */
    /* background-image: url("http://wallpaperstock.net/mountain--yellow-forest_wallpapers_48713_1920x1200.jpg"); */
    /* background-image: url("https://images5.alphacoders.com/772/thumb-1920-772004.jpg"); */
    /* background-image: url("https://images0.westend61.de/0000781678pw/happy-young-woman-looking-at-smartphone-KKAF00707.jpg"); */
    /* background-image: url("https://wallpaperaccess.com/full/38598.jpg"); */
}

.oq-login video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

.oq-login .oq-login-opacity {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    display: -webkit-flex;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, .3), rgba(0, 0, 0, .7));
}

.oq-login .content-left {
    display: flex;
    display: -webkit-flex;
    width: 50%;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.oq-login .content-right {
    display: flex;
    display: -webkit-flex;
    width: 50%;
}

.oq-login .content-right.center,
.oq-login .content-left.center {
    align-items: center;
    justify-content: center;
}

.oq-login .oq-login-content {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    padding: 20px;
}

.oq-login .oq-login-container {
    
}

.oq-login .oq-login-container img {
    width: 44%;
}

.oq-login .oq-login-container h1 {
    font-size: 30px;
    color: rgba(255, 255, 255, .9);
}

.oq-login .oq-login-container p {
    font-size: 18px;
    color: rgba(255, 255, 255, .7);
}

.oq-login .oq-center {
    align-items: center;
    justify-content: center;
    text-align: center;
}

.oq-login .oq-login-card {
    background-color: #fff;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .3);
    border-radius: 10px;
    padding: 35px;
    max-width: 450px;
    min-width: 430px;
}

.oq-login .oq-login-card .oq-login-card-header {
    padding-bottom: 25px;
}

.oq-login .oq-login-card .oq-login-card-header h2 {
    font-size: 25px;
    color: rgba(0, 0, 0, .7);
    margin-bottom: 20px;
}

.oq-login .oq-login-card .oq-login-card-header p {
    font-size: 14px;
    color: rgba(0, 0, 0, .5);
}

.oq-login .oq-login-card .oq-login-card-content {
    padding-top: 25px;
    padding-bottom: 25px;
}

.oq-login .oq-login-card .oq-login-card-action {
    padding-bottom: 25px;
}

.oq-login .oq-input-field {
    margin-bottom: 20px;
    font-family: var(--oq-font-book);
}

.oq-login .oq-input-field input {
    outline: none;
    padding: 20px 10px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 5px;
    color: rgba(0, 0, 0, .7);
    font-size: 14px;
    background-color: transparent;
}

.oq-login .oq-input-field label {
    font-weight: bold;
    margin-bottom: 10px;
}

.oq-login .oq-login-card-action {
    display: flex;
    display: -webkit-flex;
}

.oq-login .oq-login-btn {
    transition: ease .3s;
    border: none;
    outline: none;
    padding: 15px 20px;
    border-radius: 5px;
    width: 100%;
    font-weight: bold;
    background-color: rgb(0, 0, 0);
    color: #fff;
    text-transform: uppercase;
    font-family: var(--oq-font-book);
}

.oq-login .oq-login-btn:hover {
    background-color: rgb(68, 68, 68);
}
