/* !GHOTHAM */

@font-face {
  font-family: 'Gotham Rounded Bold';
  src: url('utils/Fonts/Gotham/GOTHAMROUNDEDBOLD.TTF');
}

@font-face {
  font-family: 'Gotham Rounded Book';
  src: url('utils/Fonts/Gotham/GOTHAMROUNDEDBOOK.TTF');
}

@font-face {
  font-family: 'Gotham Rounded Regular';
  src: url('utils/Fonts/Gotham/GOTHAMROUNDEDBOOK.TTF');
}

@font-face {
  font-family: 'Gotham Rounded Light';
  src: url('utils/Fonts/Gotham/GOTHAMROUNDEDLIGHT.TTF');
}

@font-face {
  font-family: 'Gotham Rounded Medium';
  src: url('utils/Fonts/Gotham/GOTHAMROUNDEDMEDIUM.TTF');
}

/* !LATO */

@font-face {
  font-family: 'Lato Bold';
  src: url('utils/Fonts/Lato/Lato-Black.ttf');
}

@font-face {
  font-family: 'Lato Book';
  src: url('utils/Fonts/Lato/Lato-Regular.ttf');
}

@font-face {
  font-family: 'Lato Regular';
  src: url('utils/Fonts/Lato/Lato-Regular.ttf');
}

@font-face {
  font-family: 'Lato Light';
  src: url('utils/Fonts/Lato/Lato-Light.ttf');
}

@font-face {
  font-family: 'Lato Medium';
  src: url('utils/Fonts/Lato/Lato-Bold.ttf');
}

/* !OpenSans */

@font-face {
  font-family: 'OpenSans Bold';
  src: url('utils/Fonts/OpenSans/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'OpenSans Book';
  src: url('utils/Fonts/OpenSans/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'OpenSans Regular';
  src: url('utils/Fonts/OpenSans/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'OpenSans Light';
  src: url('utils/Fonts/OpenSans/OpenSans-Light.ttf');
}

@font-face {
  font-family: 'OpenSans Medium';
  src: url('utils/Fonts/OpenSans/OpenSans-SemiBold.ttf');
}

/* !Roboto */

@font-face {
  font-family: 'Roboto Bold';
  src: url('utils/Fonts/Roboto/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Roboto Book';
  src: url('utils/Fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto Regular';
  src: url('utils/Fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto Light';
  src: url('utils/Fonts/Roboto/Roboto-Light.ttf');
}

@font-face {
  font-family: 'Roboto Medium';
  src: url('utils/Fonts/Roboto/Roboto-Medium.ttf');
}

:root {
  --primary-color: #000000;
  --primary-color-dark: hsl(345.4, 84.2%, 18.8%);
  --secondary-color: #ffffff;
  --oq-font-regular: 'Gotham Rounded Regular';
  --oq-font-bold: 'Gotham Rounded Bold';
  --oq-font-book: 'Gotham Rounded Book';
  --oq-font-medium: 'Gotham Rounded Medium';
  --oq-font-light: 'Gotham Rounded Light';
}

body,
html {
  height: 100vh;
  background-color: #f8f8f8;
  overflow-x: hidden;
}

body {
  font-family: var(--oq-font-book);
}

.background-img {
  /* background: url(AdminMaster/Images/background.jpg) no-repeat center center; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}

.app {
  margin: 0px !important;
}

body {
  font-family: var(--oq-font-book), sans-serif;
  background: #f5f9fa;
}

@-webkit-keyframes wrench {
  0% {
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }
  8% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
  }
  10% {
    -webkit-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  18% {
    -webkit-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  20% {
    -webkit-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  28% {
    -webkit-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  30% {
    -webkit-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  38% {
    -webkit-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  40% {
    -webkit-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  48% {
    -webkit-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  50% {
    -webkit-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  58% {
    -webkit-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  60% {
    -webkit-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  68% {
    -webkit-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes wrench {
  0% {
    -webkit-transform: rotate(-12deg);
    -ms-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }
  8% {
    -webkit-transform: rotate(12deg);
    -ms-transform: rotate(12deg);
    transform: rotate(12deg);
  }
  10% {
    -webkit-transform: rotate(24deg);
    -ms-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  18% {
    -webkit-transform: rotate(-24deg);
    -ms-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  20% {
    -webkit-transform: rotate(-24deg);
    -ms-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  28% {
    -webkit-transform: rotate(24deg);
    -ms-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  30% {
    -webkit-transform: rotate(24deg);
    -ms-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  38% {
    -webkit-transform: rotate(-24deg);
    -ms-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  40% {
    -webkit-transform: rotate(-24deg);
    -ms-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  48% {
    -webkit-transform: rotate(24deg);
    -ms-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  50% {
    -webkit-transform: rotate(24deg);
    -ms-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  58% {
    -webkit-transform: rotate(-24deg);
    -ms-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  60% {
    -webkit-transform: rotate(-24deg);
    -ms-transform: rotate(-24deg);
    transform: rotate(-24deg);
  }
  68% {
    -webkit-transform: rotate(24deg);
    -ms-transform: rotate(24deg);
    transform: rotate(24deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.faa-wrench.animated,
.faa-wrench.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-wrench {
  -webkit-animation: wrench 2.5s ease infinite;
  animation: wrench 2.5s ease infinite;
  transform-origin-x: 90%;
  transform-origin-y: 35%;
  transform-origin-z: initial;
}

/* BELL */

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  2% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  4% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  6% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  8% {
    -webkit-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }
  10% {
    -webkit-transform: rotate(22deg);
    transform: rotate(22deg);
  }
  12% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  14% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  16% {
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }
  18% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
  }
  20% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes ring {
  0% {
    -webkit-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  2% {
    -webkit-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  4% {
    -webkit-transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  6% {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  8% {
    -webkit-transform: rotate(-22deg);
    -ms-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }
  10% {
    -webkit-transform: rotate(22deg);
    -ms-transform: rotate(22deg);
    transform: rotate(22deg);
  }
  12% {
    -webkit-transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  14% {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  16% {
    -webkit-transform: rotate(-12deg);
    -ms-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }
  18% {
    -webkit-transform: rotate(12deg);
    -ms-transform: rotate(12deg);
    transform: rotate(12deg);
  }
  20% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.faa-ring.animated,
.faa-ring.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-ring {
  -webkit-animation: ring 2s ease infinite;
  animation: ring 2s ease infinite;
  transform-origin-x: 50%;
  transform-origin-y: 0px;
  transform-origin-z: initial;
}

/* VERTICAL */

@-webkit-keyframes vertical {
  0% {
    -webkit-transform: translate(0, -3px);
    transform: translate(0, -3px);
  }
  4% {
    -webkit-transform: translate(0, 3px);
    transform: translate(0, 3px);
  }
  8% {
    -webkit-transform: translate(0, -3px);
    transform: translate(0, -3px);
  }
  12% {
    -webkit-transform: translate(0, 3px);
    transform: translate(0, 3px);
  }
  16% {
    -webkit-transform: translate(0, -3px);
    transform: translate(0, -3px);
  }
  20% {
    -webkit-transform: translate(0, 3px);
    transform: translate(0, 3px);
  }
  22% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes vertical {
  0% {
    -webkit-transform: translate(0, -3px);
    -ms-transform: translate(0, -3px);
    transform: translate(0, -3px);
  }
  4% {
    -webkit-transform: translate(0, 3px);
    -ms-transform: translate(0, 3px);
    transform: translate(0, 3px);
  }
  8% {
    -webkit-transform: translate(0, -3px);
    -ms-transform: translate(0, -3px);
    transform: translate(0, -3px);
  }
  12% {
    -webkit-transform: translate(0, 3px);
    -ms-transform: translate(0, 3px);
    transform: translate(0, 3px);
  }
  16% {
    -webkit-transform: translate(0, -3px);
    -ms-transform: translate(0, -3px);
    transform: translate(0, -3px);
  }
  20% {
    -webkit-transform: translate(0, 3px);
    -ms-transform: translate(0, 3px);
    transform: translate(0, 3px);
  }
  22% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.faa-vertical.animated,
.faa-vertical.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-vertical {
  -webkit-animation: vertical 2s ease infinite;
  animation: vertical 2s ease infinite;
}

/* HORIZONTAL */

@-webkit-keyframes horizontal {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  6% {
    -webkit-transform: translate(5px, 0);
    transform: translate(5px, 0);
  }
  12% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  18% {
    -webkit-transform: translate(5px, 0);
    transform: translate(5px, 0);
  }
  24% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  30% {
    -webkit-transform: translate(5px, 0);
    transform: translate(5px, 0);
  }
  36% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes horizontal {
  0% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  6% {
    -webkit-transform: translate(5px, 0);
    -ms-transform: translate(5px, 0);
    transform: translate(5px, 0);
  }
  12% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  18% {
    -webkit-transform: translate(5px, 0);
    -ms-transform: translate(5px, 0);
    transform: translate(5px, 0);
  }
  24% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  30% {
    -webkit-transform: translate(5px, 0);
    -ms-transform: translate(5px, 0);
    transform: translate(5px, 0);
  }
  36% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.faa-horizontal.animated,
.faa-horizontal.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-horizontal {
  -webkit-animation: horizontal 2s ease infinite;
  animation: horizontal 2s ease infinite;
}

/* FLASHING */

@-webkit-keyframes flash {
  0%,
  100%,
  50% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

@keyframes flash {
  0%,
  100%,
  50% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

.faa-flash.animated,
.faa-flash.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-flash {
  -webkit-animation: flash 2s ease infinite;
  animation: flash 2s ease infinite;
}

/* BOUNCE */

@-webkit-keyframes bounce {
  0%,
  10%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  10%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.faa-bounce.animated,
.faa-bounce.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-bounce {
  -webkit-animation: bounce 2s ease infinite;
  animation: bounce 2s ease infinite;
}

/* SPIN */

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.faa-spin.animated,
.faa-spin.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-spin {
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

/* FLOAT */

@-webkit-keyframes float {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes float {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-6px);
    -ms-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.faa-float.animated,
.faa-float.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-float {
  -webkit-animation: float 2s linear infinite;
  animation: float 2s linear infinite;
}

/* PULSE */

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.faa-pulse.animated,
.faa-pulse.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-pulse {
  -webkit-animation: pulse 2s linear infinite;
  animation: pulse 2s linear infinite;
}

/* SHAKE */

.faa-shake.animated,
.faa-shake.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-shake {
  -webkit-animation: wrench 2.5s ease infinite;
  animation: wrench 2.5s ease infinite;
}

/* TADA */

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  10%,
  20% {
    -webkit-transform: scale(0.9) rotate(-8deg);
    transform: scale(0.9) rotate(-8deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: scale(1.3) rotate(8deg);
    transform: scale(1.3) rotate(8deg);
  }
  40%,
  60% {
    -webkit-transform: scale(1.3) rotate(-8deg);
    transform: scale(1.3) rotate(-8deg);
  }
  80% {
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}

@keyframes tada {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  10%,
  20% {
    -webkit-transform: scale(0.9) rotate(-8deg);
    -ms-transform: scale(0.9) rotate(-8deg);
    transform: scale(0.9) rotate(-8deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: scale(1.3) rotate(8deg);
    -ms-transform: scale(1.3) rotate(8deg);
    transform: scale(1.3) rotate(8deg);
  }
  40%,
  60% {
    -webkit-transform: scale(1.3) rotate(-8deg);
    -ms-transform: scale(1.3) rotate(-8deg);
    transform: scale(1.3) rotate(-8deg);
  }
  80% {
    -webkit-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}

.faa-tada.animated,
.faa-tada.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-tada {
  -webkit-animation: tada 2s linear infinite;
  animation: tada 2s linear infinite;
}

/* PASSING */

@-webkit-keyframes passing {
  0% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0;
  }
  50% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    opacity: 0;
  }
}

@keyframes passing {
  0% {
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0;
  }
  50% {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
    opacity: 0;
  }
}

.faa-passing.animated,
.faa-passing.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-passing {
  -webkit-animation: passing 3s linear infinite;
  animation: passing 3s linear infinite;
}

/* WAVE */

@-webkit-keyframes burst {
  0% {
    opacity: 0.6;
  }
  50% {
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes burst {
  0% {
    opacity: 0.6;
  }
  50% {
    -webkit-transform: scale(1.8);
    -ms-transform: scale(1.8);
    transform: scale(1.8);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.faa-burst.animated,
.faa-burst.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-burst {
  -webkit-animation: burst 3s infinite linear;
  animation: burst 3s infinite linear;
}

p {
  font-family: var(--oq-font-book), sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  transition: all 0.3s;
}

.menu-link,
.menu-link:hover,
.menu-link:hover {
  color: #838383;
}

strong {
  color: #000;
}

label {
  font-weight: normal;
}

.navbar {
  padding: 10px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 0px;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
  margin-left: -20px;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.navbar-btn:hover {
  box-shadow: none;
  outline: none !important;
  border: none;
  color: #fff;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

i,
span {
  display: inline-block;
}

.wrapper {
  display: flex;
  align-items: stretch;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  min-height: 100%;
  background: #272727;
  background-clip: content-box;
  color: #fff;
  transition: 0.3s;
  z-index: 999;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

#sidebar.active {
  min-width: 90px;
  max-width: 110px;
  text-align: center;
}

#sidebar.active .sidebar-header h3,
#sidebar.active .CTAs {
  display: none;
}

#sidebar.active .sidebar-header img.hidden-small,
#sidebar.active .CTAs {
  display: none;
}

#sidebar.active .sidebar-header strong {
  display: block;
}

#sidebar ul li a {
  text-align: left;
}

#sidebar.active ul li a {
  padding: 12px 10px;
  text-align: center;
  font-size: 0.85em;
  border-right: 10px solid transparent;
}

#sidebar.active ul li span {
  display: none;
}

#sidebar.active ul li a i {
  margin-right: 0;
  /* display: block; */
  font-size: 1.8em;
  /* margin-bottom: 5px; */
}

#sidebar.active ul ul a {
  padding: 10px !important;
}

#sidebar.active a[aria-expanded='false']::before,
#sidebar.active a[aria-expanded='true']::before {
  top: auto;
  bottom: 5px;
  right: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

#sidebar .sidebar-header {
  background: #272727;
}

#sidebar .sidebar-header strong {
  display: none;
  font-size: 1.8em;
}

/*
#sidebar ul.components {
    padding: 20px 0;
} */

#sidebar ul li a {
  padding: 12px 10px;
  font-family: var(--oq-font-medium);
  font-size: 0.9em;
  display: block;
}

#sidebar ul li a i {
  font-size: 1.2em;
}

#sidebar ul li a:hover {
  font-family: var(--oq-font-medium);
  /* color: #000; */
  background: #e6e6e6;
  /* opacity: 0.5; */
}

#sidebar ul li a:hover .sidebarText {
  /* color: #000; */
}

#sidebar ul li a i {
  margin-left: 5%;
  margin-right: 5%;
}

#sidebar ul li.active > a,
a[aria-expanded='true'] {
  color: var(--primary-color);
  background: var(--secondary-color);
  border-right: 10px solid var(--primary-color);
}

nav#sidebar.active li#logout_sidebar_button {
  min-width: 90px;
  max-width: 90px;
}

a[data-toggle='collapse'] {
  position: relative;
}

a[aria-expanded='false']::before,
a[aria-expanded='true']::before {
  content: '\e259';
  display: block;
  position: absolute;
  right: 20px;
  font-family: 'Glyphicons Halflings';
  font-size: 0.6em;
}

a[aria-expanded='true']::before {
  content: '\e260';
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

#content {
  min-height: 100vh;
  transition: all 0.3s;
  width: stretch;
}

@media (min-width: 768px) {
  div#navbar {
    padding-left: 20px;
  }
}

@media (max-width: 768px) {
  #sidebar {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
    margin-left: -80px !important;
  }
  a[aria-expanded='false']::before,
  a[aria-expanded='true']::before {
    top: auto;
    bottom: 5px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }
  #sidebar.active {
    margin-left: 0 !important;
  }
  nav#sidebar li#logout_sidebar_button {
    min-width: 0px;
    max-width: 0px;
  }
  #sidebar .sidebar-header h3,
  #sidebar .CTAs {
    display: none;
  }
  #sidebar .sidebar-header img.hidden-small,
  #sidebar .CTAs {
    display: none;
  }
  #sidebar .sidebar-header strong {
    display: block;
  }
  #sidebar ul li a {
    padding: 20px 10px;
  }
  #sidebar ul li a span {
    display: none;
  }
  #sidebar ul li a i {
    margin-right: 0;
    display: block;
  }
  #sidebar ul ul a {
    padding: 10px !important;
  }
  #sidebar ul li a i {
    font-size: 1.3em;
  }
  #sidebar {
    margin-left: 0;
  }
  #content {
    padding-left: 0px;
  }
  .navbar {
    margin-left: 0px;
  }
}

.card-form-container.card-form {
  max-width: 50%;
  padding: 40px 40px;
}

@media (max-width: 767px) {
  .card-form-container.card-form {
    max-width: 100%;
  }
}

.card-form {
  background-color: #ffff;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 100%;
  height: auto;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.form-signin input {
  background: transparent;
}

.btn.btn-signin {
  background-color: #39d36a;
  padding: 0px;
  font-weight: 700;
  font-size: 18px;
  height: 66px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: none;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.btn.btn-signin:hover,
.btn.btn-signin:active,
.btn.btn-signin:focus {
  background-color: rgb(12, 97, 33);
}

.btn.btn-signin:disabled {
  background-color: rgb(146, 199, 163);
}

.forgot-password {
  color: rgb(104, 145, 162);
}

.forgot-password:hover,
.forgot-password:active,
.forgot-password:focus {
  color: rgb(12, 97, 33);
}

.text-center {
  text-align: center;
}

.text-error {
  color: #c12426;
}

.form-signup input {
  direction: ltr;
  height: 66px;
  font-size: 16px;
  border: 3px solid #b7b7b7;
}

.form-signup input,
.form-signup button {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  z-index: 1;
  position: relative;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.form-signup .form-control:focus {
  border-color: #b7b7b7;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgb(104, 145, 162);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(104, 145, 162);
}

.btn.btn-signup {
  background-color: #39d36a;
  padding: 0px;
  font-weight: 700;
  font-size: 18px;
  height: 66px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: none;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.btn.btn-signup:hover,
.btn.btn-signup:active,
.btn.btn-signup:focus {
  background-color: rgb(12, 97, 33);
}

.btn.btn-signup:disabled {
  background-color: rgb(146, 199, 163);
}

.error-template {
  padding: 40px 15px;
  text-align: center;
}

.error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}

.error-actions .btn {
  margin-right: 10px;
}

.form-forgotPassword #inputEmail {
  direction: ltr;
  height: 66px;
  font-size: 16px;
  border: 3px solid #b7b7b7;
}

.form-forgotPassword input[type='email'],
.form-forgotPassword button {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  z-index: 1;
  position: relative;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.form-forgotPassword .form-control:focus {
  border-color: #b7b7b7;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgb(104, 145, 162);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(104, 145, 162);
}

.btn.btn-forgotPassword {
  background-color: #39d36a;
  padding: 0px;
  font-weight: 700;
  font-size: 18px;
  height: 66px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: none;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.btn.btn-forgotPassword:hover,
.btn.btn-forgotPassword:active,
.btn.btn-forgotPassword:focus {
  background-color: rgb(12, 97, 33);
}

.btn.btn-forgotPassword:disabled {
  background-color: rgb(146, 199, 163);
}

.text-white {
  color: #fff !important;
}

/* Text-transform from Bootstrap 5 */

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

/* Background from Bootstrap 4 */

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #868e96 !important;
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #39d36a !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-success-secondary {
  background-color: #39d36a !important;
}

a.bg-success-secondary:focus,
a.bg-success-secondary:hover,
button.bg-success-secondary:focus,
button.bg-success-secondary:hover {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #3fbbf8 !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc42c !important;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #f95455 !important;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important;
}

.bg-cyan {
  background-color: #d63b6f !important;
}

a.bg-cyan:focus,
a.bg-cyan:hover,
button.bg-cyan:focus,
button.bg-cyan:hover {
  background-color: #8a2245 !important;
}

.bg-red {
  color: #fff;
  background-color: var(--primary-color);
}

a.bg-blue:focus,
a.bg-blue:hover,
button.bg-blue:focus,
button.bg-blue:hover {
  color: #fff !important;
  background-color: #2e6c86 !important;
}

.bg-blue-secondary {
  color: #fff;
  background-color: #0275af;
}

a.bg-blue-secondary:focus,
a.bg-blue-secondary:hover,
button.bg-blue-secondary:focus,
button.bg-blue-secondary:hover {
  background-color: #02405f !important;
  color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.text-success-secondary {
  color: #39d36a;
}

.text-danger {
  color: #f95455;
}

.btn-transparent {
  border-color: transparent !important;
}

.card.card-data {
  border-radius: 6px;
  padding: 1px 0px;
  margin-bottom: 5%;
}

@media (min-width: 1200px) {
  .card.card-data {
    max-width: 80%;
  }
}

.box-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.minimal-box-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.box-shadow:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.card.card-weather,
.card.card-clock {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card.card-weather:hover,
.card.card-clock:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.table td,
.table th {
  border: 0;
}

.table thead > tr > th {
  vertical-align: text-top;
  border-bottom: none;
  box-shadow: none;
  text-shadow: none;
}

.card-big {
  min-height: 140px;
}

.p-5-top {
  padding-top: 5%;
}

.p-5 {
  padding: 5px;
}

.p-1-bottom {
  padding-bottom: 1%;
}

.p-3-bottom {
  padding-bottom: 3%;
}

.p-3-top {
  padding-top: 3%;
}

.p-3-right {
  padding-right: 3%;
}

.p-10-top {
  padding-top: 10%;
}

.p-10-bottom {
  padding-bottom: 10%;
}

.p-16-bottom {
  padding-bottom: 16%;
}

.p-5-bottom {
  padding-bottom: 5%;
}

.p-1-bottom {
  padding-bottom: 1%;
}

.font-size-20 {
  font-size: 20px !important;
}

.input-big {
  direction: ltr;
  height: 50px;
  font-size: 16px;
  border: 1px solid #4aa3e2;
  width: 100%;
  display: block;
  margin-bottom: 10px;
  z-index: 1;
  position: relative;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Gotham Rounded Book', sans-serif, 'FontAwesome';
}

.input-bordered {
  direction: ltr;
  border: 1px solid #4aa3e2;
  font-family: 'Gotham Rounded Book', sans-serif, 'FontAwesome';
  border-radius: 2px;
}

.input-bordered:disabled {
  border: 1px solid #90c0f8;
}

.btn-space {
  margin-right: 3%;
}

.btn-big {
  padding: 0px;
  font-weight: 700;
  font-size: 18px;
  height: 66px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: none;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

@media (min-width: 768px) {
  .btn-large {
    height: 50px;
    width: 25%;
  }
}

#footer {
  padding-top: 5%;
}

.popup-close {
  width: 34px;
  height: 34px;
  padding-top: 4px;
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 20px;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  border-radius: 100%;
  background: transparent;
  border: solid 4px #808080;
}

.popup-close:after,
.popup-close:before {
  content: '';
  position: absolute;
  top: 11px;
  left: 5px;
  height: 4px;
  width: 16px;
  border-radius: 30px;
  background: #808080;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.popup-close:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.popup-close:hover {
  -webkit-transform: translate(50%, -50%) rotate(180deg);
  transform: translate(50%, -50%) rotate(180deg);
  background: #f00;
  text-decoration: none;
  border-color: #f00;
}

.popup-close:hover:after,
.popup-close:hover:before {
  background: #fff;
}

.fa-1x {
  vertical-align: middle;
}

.fa-2x {
  vertical-align: middle;
}

.fa-3x {
  vertical-align: middle;
}

.fa-4x {
  vertical-align: middle;
}

.fa-5x {
  vertical-align: middle;
}

.fa-padding-right {
  padding-right: 15px;
}

.fa-padding {
  padding: 0px 3%;
}

.modal-header {
  border-bottom: none;
}

.modal-content {
  border: 1px solid #ffffff;
}

.modal-footer {
  border-top: none;
}

[hidden] {
  display: none !important;
}

textarea {
  resize: vertical;
}

#logout_sidebar_button {
  position: absolute;
  bottom: 0;
  width: 250px;
}

#sidebar.active ul li#logout_sidebar_button a {
  width: 90px;
}

.card-image {
  float: left;
  padding-left: 30px;
  padding-right: 0px;
  padding-top: 30px;
  padding-bottom: 15px;
}

.borderless td,
.borderless th {
  border: none !important;
}

#iconified,
.iconified,
.react-bs-table-search-form {
  font-family: 'FontAwesome', 'Gotham Rounded Book', sans-serif !important;
}

.card-text.small.text-white {
  font-size-adjust: 0.52;
}

button i.fa {
  padding-right: 2%;
}

.fa-padding-right-2 {
  margin-right: 2%;
}

.arrow-toggle {
  transition: all 1s ease-in-out;
}

.arrow-toggle:after {
  font-family: 'Glyphicons Halflings';
  content: '\e114';
  float: right;
  color: gray;
}

.arrow-toggle.collapsed:after {
  content: '\e080';
}

.clickable {
  cursor: pointer;
}

.results tr[visible='false'],
.no-result {
  display: none;
}

.results tr[visible='true'] {
  display: table-row;
}

.react-datepicker {
  font-size: 1em;
}

.react-datepicker__header {
  padding-top: 0.8em;
  text-transform: capitalize;
}

.react-datepicker__month {
  margin: 0.4em 1em;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}

.react-datepicker__current-month {
  font-size: 1em;
}

.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}

.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}

.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}

.react-datepicker option {
  text-transform: capitalize;
}

.react-datepicker__month-select {
  text-transform: capitalize;
}

.react-datepicker__input-container {
  display: block;
}

.spinner div {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.footer {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  height: 10%;
  padding: 2%;
  text-align: center;
  vertical-align: bottom;
}

@media (max-width: 767px) {
  .footer {
    padding-bottom: 20%;
  }
}

h4.collapsed {
  color: #777;
  margin-bottom: 20px;
}

.table-responsive {
  border: none !important;
}

.text-info {
  color: #5bc0de;
}

.text-info:hover {
  color: #31b0d5;
}

.card-black {
  color: #000 !important;
  border: 1px solid black;
}

.text-truncate div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link {
  color: #337ab7;
}

.dashboard_card_1 {
  background-color: var(--primary-color);
}

.dashboard_card_2 {
  background-color: var(--primary-color);
}

.dash_icon {
  font-size: 650%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.backIcon {
  font-size: 130%;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* .img-logo-transaction{
    -webkit-animation: fadein 2s;
    -moz-animation: fadein 2s;
     -ms-animation: fadein 2s;
      -o-animation: fadein 2s;
         animation: fadein 2s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
} */

.card-secondary {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.card-secondary:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card-secondary-container {
  padding: 1% 2%;
}

.demo-wrapper {
  background-color: rgb(235, 235, 228);
}

.demo-editor {
  margin-right: 2%;
  margin-left: 2%;
  margin-bottom: 2%;
}

.mainSection {
  padding: 20px;
  padding-left: 40px;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.menu-link svg {
  margin-right: 5px;
}

.navbar-text {
  margin-right: 0px !important;
}

.loginButton {
  font-family: var(--oq-font-medium);
  color: black;
  background-color: #eaeaea;
  padding-top: 5%;
  padding-bottom: 5%;
}

.loginButton:hover {
  color: black !important;
  filter: brightness(85%);
  padding-top: 5%;
  padding-bottom: 5%;
  cursor: pointer;
}

.marginTopLogin {
  margin-top: 9%;
}

#sidebar img.img-logo-transaction {
  padding: 40px 20px;
  /* padding-bottom: 5px; */
}

.answer-square {
  background: var(--primary-color);
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: 3vw;
  margin-right: 10px;
}

.answer-letter {
  margin: 0;
  color: white;
}

.m-top-0 {
  margin-top: 0;
}

.custom-control-inline {
  display: inline-flex !important;
}

.custom-control {
  position: relative !important;
  display: block !important;
  min-height: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.custom-control-label {
  padding-left: 1rem !important;
}
