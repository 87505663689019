.ReactTable {
  border: none;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  box-shadow: unset;
  -webkit-box-shadow: unset;
  border-right: unset;
  padding-left: 0;
  margin: 0;
  font-size: initial;
}

.ReactTable .rt-thead.-header {
  box-shadow: unset;
  -webkit-box-shadow: unset;
}

.ReactTable .rt-tbody .rt-td {
  border-right: unset;
  min-height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-size: initial;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: unset;
}

.ReactTable .rt-tr-group {
  max-width: 100%;
}

.ReactTable .rt-thead .rt-tr {
  text-align: left;
  font-weight: bold;
  padding-bottom: 10px;
}

.ReactTable .cursor-pointer {
  cursor: pointer !important;
  border-right: none;
  margin: 5px !important;
}

@media (max-width: 767px) {
  .ReactTable .-pagination {
    flex-direction: column;
  }
}

.ReactTable .rt-thead .rt-resizable-header-content #tgname {
  text-align: center;
}
