.oq-close-modal {
    position: absolute;
    outline: none !important;
    border: none;
}

.oq-modal {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
    min-width: 300px;
    max-width: 450px;
    overflow: hidden;
    padding: 0;
    margin: 20px;
}

.oq-cover-modal {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    height: 200px;
    overflow: hidden;
}

.oq-cover-modal::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 200px;
    background: linear-gradient(transparent, transparent, transparent, rgba(0,0,0,0.3));
}

.oq-wrapper-contents .oq-alert-icon {
    width: 50px;
    margin-bottom: 20px;
}

.oq-wrapper-contents .title-alert {
    font-size: 23px;
    margin-bottom: 20px;
    line-height: normal;
    word-wrap: break-word;
}

.oq-wrapper-contents .title-alert.title-normal {
    font-size: 25px;
}

.oq-wrapper-contents .subtitle-alert {
    font-size: 25px;
    margin-bottom: 20px;
    line-height: normal;
    word-wrap: break-word;
}

.oq-wrapper-contents .subtitle-alert.subtitle-normal {
    font-size: 20px;
}

.oq-wrapper-contents .content-alert {
    word-wrap: break-word;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
}

.oq-group-horizontal .oq-btn.btn-confirm {
    background-color: var(--primary-color);
    color: #fff;
}

.oq-group-horizontal .oq-btn.btn-confirm:hover {
    background-color: var(--primary-color-dark);
}

.oq-group-horizontal .oq-btn.btn-negative {
    background-color: #e53935;
    color: #fff;
}

.oq-group-horizontal .oq-btn.btn-negative:hover {
    filter: brightness(90%);
}

.custom-link {
    cursor: pointer;
    color: #1675e0;
}

.custom-link:hover {
    text-decoration: underline;
}

@media (min-width: 360px) and (max-width: 460px) {
    .oq-modal {
        padding: 0 !important;
    }
}

@media (max-width: 768px) {
    .oq-modal {
        padding: 0 !important;
    }
}