.swiper-pagination-bullet-active {
  background-color: #fff;
}

.swiper-pagination-bullet {
  background-color: #fff;
}

.swiper-scrollbar-drag {
  background: rgba(255, 255, 255, 0.5);
}

.swiper-button-prev,
.swiper-button-next {
}

.fa-swiper {
  position: absolute;
  top: 50%;
  color: #fff;
  font-size: 2.5em;
  position: absolute;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
}

.fa-swiper-right {
  right: 3%;
  left: auto;
}

.fa-swiper-left {
  left: 3%;
  right: auto;
}

.fa-swiper-tutorial {
  color: #b3bdd8 !important;
}

.swiper-slide {
  height: 100%;
  text-align: center !important;
}

.col-md-12 div div .swiper-wrapper {
  height: 75vh;
}

.swiper-img {
  height: 100%;
  width: auto;
  object-fit: cover;
}

.fade {
  display: none;
}
