@-webkit-keyframes wrench {
    0% {
      -webkit-transform: rotate(-12deg);
      transform: rotate(-12deg);
    }
  
    8% {
      -webkit-transform: rotate(12deg);
      transform: rotate(12deg);
    }
  
    10% {
      -webkit-transform: rotate(24deg);
      transform: rotate(24deg);
    }
  
    18% {
      -webkit-transform: rotate(-24deg);
      transform: rotate(-24deg);
    }
  
    20% {
      -webkit-transform: rotate(-24deg);
      transform: rotate(-24deg);
    }
  
    28% {
      -webkit-transform: rotate(24deg);
      transform: rotate(24deg);
    }
  
    30% {
      -webkit-transform: rotate(24deg);
      transform: rotate(24deg);
    }
  
    38% {
      -webkit-transform: rotate(-24deg);
      transform: rotate(-24deg);
    }
  
    40% {
      -webkit-transform: rotate(-24deg);
      transform: rotate(-24deg);
    }
  
    48% {
      -webkit-transform: rotate(24deg);
      transform: rotate(24deg);
    }
  
    50% {
      -webkit-transform: rotate(24deg);
      transform: rotate(24deg);
    }
  
    58% {
      -webkit-transform: rotate(-24deg);
      transform: rotate(-24deg);
    }
  
    60% {
      -webkit-transform: rotate(-24deg);
      transform: rotate(-24deg);
    }
  
    68% {
      -webkit-transform: rotate(24deg);
      transform: rotate(24deg);
    }
  
    75% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
  
  @keyframes wrench {
    0% {
      -webkit-transform: rotate(-12deg);
      -ms-transform: rotate(-12deg);
      transform: rotate(-12deg);
    }
  
    8% {
      -webkit-transform: rotate(12deg);
      -ms-transform: rotate(12deg);
      transform: rotate(12deg);
    }
  
    10% {
      -webkit-transform: rotate(24deg);
      -ms-transform: rotate(24deg);
      transform: rotate(24deg);
    }
  
    18% {
      -webkit-transform: rotate(-24deg);
      -ms-transform: rotate(-24deg);
      transform: rotate(-24deg);
    }
  
    20% {
      -webkit-transform: rotate(-24deg);
      -ms-transform: rotate(-24deg);
      transform: rotate(-24deg);
    }
  
    28% {
      -webkit-transform: rotate(24deg);
      -ms-transform: rotate(24deg);
      transform: rotate(24deg);
    }
  
    30% {
      -webkit-transform: rotate(24deg);
      -ms-transform: rotate(24deg);
      transform: rotate(24deg);
    }
  
    38% {
      -webkit-transform: rotate(-24deg);
      -ms-transform: rotate(-24deg);
      transform: rotate(-24deg);
    }
  
    40% {
      -webkit-transform: rotate(-24deg);
      -ms-transform: rotate(-24deg);
      transform: rotate(-24deg);
    }
  
    48% {
      -webkit-transform: rotate(24deg);
      -ms-transform: rotate(24deg);
      transform: rotate(24deg);
    }
  
    50% {
      -webkit-transform: rotate(24deg);
      -ms-transform: rotate(24deg);
      transform: rotate(24deg);
    }
  
    58% {
      -webkit-transform: rotate(-24deg);
      -ms-transform: rotate(-24deg);
      transform: rotate(-24deg);
    }
  
    60% {
      -webkit-transform: rotate(-24deg);
      -ms-transform: rotate(-24deg);
      transform: rotate(-24deg);
    }
  
    68% {
      -webkit-transform: rotate(24deg);
      -ms-transform: rotate(24deg);
      transform: rotate(24deg);
    }
  
    75% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
  
  .faa-wrench.animated,
  .faa-wrench.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-wrench {
    -webkit-animation: wrench 2.5s ease infinite;
    animation: wrench 2.5s ease infinite;
    transform-origin-x: 90%;
    transform-origin-y: 35%;
    transform-origin-z: initial;
  }
  
  /* BELL */
  
  @-webkit-keyframes ring {
    0% {
      -webkit-transform: rotate(-15deg);
      transform: rotate(-15deg);
    }
  
    2% {
      -webkit-transform: rotate(15deg);
      transform: rotate(15deg);
    }
  
    4% {
      -webkit-transform: rotate(-18deg);
      transform: rotate(-18deg);
    }
  
    6% {
      -webkit-transform: rotate(18deg);
      transform: rotate(18deg);
    }
  
    8% {
      -webkit-transform: rotate(-22deg);
      transform: rotate(-22deg);
    }
  
    10% {
      -webkit-transform: rotate(22deg);
      transform: rotate(22deg);
    }
  
    12% {
      -webkit-transform: rotate(-18deg);
      transform: rotate(-18deg);
    }
  
    14% {
      -webkit-transform: rotate(18deg);
      transform: rotate(18deg);
    }
  
    16% {
      -webkit-transform: rotate(-12deg);
      transform: rotate(-12deg);
    }
  
    18% {
      -webkit-transform: rotate(12deg);
      transform: rotate(12deg);
    }
  
    20% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
  
  @keyframes ring {
    0% {
      -webkit-transform: rotate(-15deg);
      -ms-transform: rotate(-15deg);
      transform: rotate(-15deg);
    }
  
    2% {
      -webkit-transform: rotate(15deg);
      -ms-transform: rotate(15deg);
      transform: rotate(15deg);
    }
  
    4% {
      -webkit-transform: rotate(-18deg);
      -ms-transform: rotate(-18deg);
      transform: rotate(-18deg);
    }
  
    6% {
      -webkit-transform: rotate(18deg);
      -ms-transform: rotate(18deg);
      transform: rotate(18deg);
    }
  
    8% {
      -webkit-transform: rotate(-22deg);
      -ms-transform: rotate(-22deg);
      transform: rotate(-22deg);
    }
  
    10% {
      -webkit-transform: rotate(22deg);
      -ms-transform: rotate(22deg);
      transform: rotate(22deg);
    }
  
    12% {
      -webkit-transform: rotate(-18deg);
      -ms-transform: rotate(-18deg);
      transform: rotate(-18deg);
    }
  
    14% {
      -webkit-transform: rotate(18deg);
      -ms-transform: rotate(18deg);
      transform: rotate(18deg);
    }
  
    16% {
      -webkit-transform: rotate(-12deg);
      -ms-transform: rotate(-12deg);
      transform: rotate(-12deg);
    }
  
    18% {
      -webkit-transform: rotate(12deg);
      -ms-transform: rotate(12deg);
      transform: rotate(12deg);
    }
  
    20% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
  
  .faa-ring.animated,
  .faa-ring.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-ring {
    -webkit-animation: ring 2s ease infinite;
    animation: ring 2s ease infinite;
    transform-origin-x: 50%;
    transform-origin-y: 0px;
    transform-origin-z: initial;
  }
  
  /* VERTICAL */
  
  @-webkit-keyframes vertical {
    0% {
      -webkit-transform: translate(0,-3px);
      transform: translate(0,-3px);
    }
  
    4% {
      -webkit-transform: translate(0,3px);
      transform: translate(0,3px);
    }
  
    8% {
      -webkit-transform: translate(0,-3px);
      transform: translate(0,-3px);
    }
  
    12% {
      -webkit-transform: translate(0,3px);
      transform: translate(0,3px);
    }
  
    16% {
      -webkit-transform: translate(0,-3px);
      transform: translate(0,-3px);
    }
  
    20% {
      -webkit-transform: translate(0,3px);
      transform: translate(0,3px);
    }
  
    22% {
      -webkit-transform: translate(0,0);
      transform: translate(0,0);
    }
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  .blink_me {
    animation: blinker 1.5s linear infinite;
  }

  @keyframes vertical {
    0% {
      -webkit-transform: translate(0,-3px);
      -ms-transform: translate(0,-3px);
      transform: translate(0,-3px);
    }
  
    4% {
      -webkit-transform: translate(0,3px);
      -ms-transform: translate(0,3px);
      transform: translate(0,3px);
    }
  
    8% {
      -webkit-transform: translate(0,-3px);
      -ms-transform: translate(0,-3px);
      transform: translate(0,-3px);
    }
  
    12% {
      -webkit-transform: translate(0,3px);
      -ms-transform: translate(0,3px);
      transform: translate(0,3px);
    }
  
    16% {
      -webkit-transform: translate(0,-3px);
      -ms-transform: translate(0,-3px);
      transform: translate(0,-3px);
    }
  
    20% {
      -webkit-transform: translate(0,3px);
      -ms-transform: translate(0,3px);
      transform: translate(0,3px);
    }
  
    22% {
      -webkit-transform: translate(0,0);
      -ms-transform: translate(0,0);
      transform: translate(0,0);
    }
  }
  
  .faa-vertical.animated,
  .faa-vertical.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-vertical {
    -webkit-animation: vertical 2s ease infinite;
    animation: vertical 2s ease infinite;
  }
  
  /* HORIZONTAL */
  
  @-webkit-keyframes horizontal {
    0% {
      -webkit-transform: translate(0,0);
      transform: translate(0,0);
    }
  
    6% {
      -webkit-transform: translate(5px,0);
      transform: translate(5px,0);
    }
  
    12% {
      -webkit-transform: translate(0,0);
      transform: translate(0,0);
    }
  
    18% {
      -webkit-transform: translate(5px,0);
      transform: translate(5px,0);
    }
  
    24% {
      -webkit-transform: translate(0,0);
      transform: translate(0,0);
    }
  
    30% {
      -webkit-transform: translate(5px,0);
      transform: translate(5px,0);
    }
  
    36% {
      -webkit-transform: translate(0,0);
      transform: translate(0,0);
    }
  }
  
  @keyframes horizontal {
    0% {
      -webkit-transform: translate(0,0);
      -ms-transform: translate(0,0);
      transform: translate(0,0);
    }
  
    6% {
      -webkit-transform: translate(5px,0);
      -ms-transform: translate(5px,0);
      transform: translate(5px,0);
    }
  
    12% {
      -webkit-transform: translate(0,0);
      -ms-transform: translate(0,0);
      transform: translate(0,0);
    }
  
    18% {
      -webkit-transform: translate(5px,0);
      -ms-transform: translate(5px,0);
      transform: translate(5px,0);
    }
  
    24% {
      -webkit-transform: translate(0,0);
      -ms-transform: translate(0,0);
      transform: translate(0,0);
    }
  
    30% {
      -webkit-transform: translate(5px,0);
      -ms-transform: translate(5px,0);
      transform: translate(5px,0);
    }
  
    36% {
      -webkit-transform: translate(0,0);
      -ms-transform: translate(0,0);
      transform: translate(0,0);
    }
  }
  
  .faa-horizontal.animated,
  .faa-horizontal.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-horizontal {
    -webkit-animation: horizontal 2s ease infinite;
    animation: horizontal 2s ease infinite;
  }
  
  /* FLASHING */
  
  @-webkit-keyframes flash {
    0%, 100%, 50% {
      opacity: 1;
    }
  
    25%, 75% {
      opacity: 0;
    }
  }
  
  @keyframes flash {
    0%, 100%, 50% {
      opacity: 1;
    }
  
    25%, 75% {
      opacity: 0;
    }
  }
  
  .faa-flash.animated,
  .faa-flash.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-flash {
    -webkit-animation: flash 2s ease infinite;
    animation: flash 2s ease infinite;
  }
  
  /* BOUNCE */
  
  @-webkit-keyframes bounce {
    0%, 10%, 20%, 50%, 80% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  
    40% {
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  
    60% {
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }
  
  @keyframes bounce {
    0%, 10%, 20%, 50%, 80% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
  
    40% {
      -webkit-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  
    60% {
      -webkit-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }
  
  .faa-bounce.animated,
  .faa-bounce.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-bounce {
    -webkit-animation: bounce 2s ease infinite;
    animation: bounce 2s ease infinite;
  }
  
  /* SPIN */
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(359deg);
      -ms-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  
  .faa-spin.animated,
  .faa-spin.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-spin {
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
  
  /* FLOAT */
  
  @-webkit-keyframes float {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  
    50% {
      -webkit-transform: translateY(-6px);
      transform: translateY(-6px);
    }
  
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  
  @keyframes float {
    0% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
  
    50% {
      -webkit-transform: translateY(-6px);
      -ms-transform: translateY(-6px);
      transform: translateY(-6px);
    }
  
    100% {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
  }
  
  .faa-float.animated,
  .faa-float.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-float {
    -webkit-animation: float 2s linear infinite;
    animation: float 2s linear infinite;
  }
  
  /* PULSE */
  
  @-webkit-keyframes pulse {
    0% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  
    50% {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
    }
  
    100% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
  
  @keyframes pulse {
    0% {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }
  
    50% {
      -webkit-transform: scale(0.8);
      -ms-transform: scale(0.8);
      transform: scale(0.8);
    }
  
    100% {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
  
  .faa-pulse.animated,
  .faa-pulse.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-pulse {
    -webkit-animation: pulse 2s linear infinite;
    animation: pulse 2s linear infinite;
  }
  
  /* SHAKE */
  
  .faa-shake.animated,
  .faa-shake.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-shake {
    -webkit-animation: wrench 2.5s ease infinite;
    animation: wrench 2.5s ease infinite;
  }
  
  /* TADA */
  
  @-webkit-keyframes tada {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  
    10%, 20% {
      -webkit-transform: scale(.9) rotate(-8deg);
      transform: scale(.9) rotate(-8deg);
    }
  
    30%, 50%, 70% {
      -webkit-transform: scale(1.3) rotate(8deg);
      transform: scale(1.3) rotate(8deg);
    }
  
    40%, 60% {
      -webkit-transform: scale(1.3) rotate(-8deg);
      transform: scale(1.3) rotate(-8deg);
    }
  
    80% {
      -webkit-transform: scale(1) rotate(0);
      transform: scale(1) rotate(0);
    }
  }
  
  @keyframes tada {
    0% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  
    10%, 20% {
      -webkit-transform: scale(.9) rotate(-8deg);
      -ms-transform: scale(.9) rotate(-8deg);
      transform: scale(.9) rotate(-8deg);
    }
  
    30%, 50%, 70% {
      -webkit-transform: scale(1.3) rotate(8deg);
      -ms-transform: scale(1.3) rotate(8deg);
      transform: scale(1.3) rotate(8deg);
    }
  
    40%, 60% {
      -webkit-transform: scale(1.3) rotate(-8deg);
      -ms-transform: scale(1.3) rotate(-8deg);
      transform: scale(1.3) rotate(-8deg);
    }
  
    80% {
      -webkit-transform: scale(1) rotate(0);
      -ms-transform: scale(1) rotate(0);
      transform: scale(1) rotate(0);
    }
  }
  
  .faa-tada.animated,
  .faa-tada.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-tada {
    -webkit-animation: tada 2s linear infinite;
    animation: tada 2s linear infinite;
  }
  
  /* PASSING */
  
  @-webkit-keyframes passing {
    0% {
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      opacity: 0;
    }
  
    50% {
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
      opacity: 1;
    }
  
    100% {
      -webkit-transform: translateX(50%);
      transform: translateX(50%);
      opacity: 0;
    }
  }
  
  @keyframes passing {
    0% {
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      opacity: 0;
    }
  
    50% {
      -webkit-transform: translateX(0%);
      -ms-transform: translateX(0%);
      transform: translateX(0%);
      opacity: 1;
    }
  
    100% {
      -webkit-transform: translateX(50%);
      -ms-transform: translateX(50%);
      transform: translateX(50%);
      opacity: 0;
    }
  }
  
  .faa-passing.animated,
  .faa-passing.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-passing {
    -webkit-animation: passing 3s linear infinite;
    animation: passing 3s linear infinite;
  }
  
  /* WAVE */
  
  @-webkit-keyframes burst {
    0% {
      opacity: .6;
    }
  
    50% {
      -webkit-transform: scale(1.8);
      transform: scale(1.8);
      opacity: 0;
    }
  
    100% {
      opacity: 0;
    }
  }
  
  @keyframes burst {
    0% {
      opacity: .6;
    }
  
    50% {
      -webkit-transform: scale(1.8);
      -ms-transform: scale(1.8);
      transform: scale(1.8);
      opacity: 0;
    }
  
    100% {
      opacity: 0;
    }
  }
  
  .faa-burst.animated,
  .faa-burst.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-burst {
    -webkit-animation: burst 3s infinite linear;
    animation: burst 3s infinite linear;
  }