.oq-btn {
    transition: 0.27s;
    outline: none !important;
    border: none !important;
    padding: 10px 15px;
    font-size: 14px;
    border-radius: 10px;
    text-transform: uppercase;
    font-weight: bold;
    background-color: #eee;
}

.oq-btn.btn-flat {
    background-color: transparent;
    border: 1px solid #ddd !important;
}

.oq-btn:hover {
    filter: brightness(90%);
}
