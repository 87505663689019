.oq-overlay {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.oq-wrapper-contents {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    padding: 30px;
    padding-top: 45px;
}

.oq-wrapper-contents.small-content {
    padding-top: 30px;
}

.oq-center {
    align-items: center;
    text-align: center;
}

.oq-wrapper-contents.oq-header {
    margin-top: 55px;
}

.oq-group-horizontal,
.oq-group-buttons,
.oq-group {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    width: 100%;
}

.oq-group img {
    margin-right: 20px;
    width: 30px;
}

.oq-group-buttons {
    flex-wrap: wrap;
}

.oq-group-buttons button {
    margin: 5px;
}

.oq-group-horizontal .oq-btn {
    margin: 0;
    border-radius: 0;
    width: 100%;
}

.oq-input-field {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.oq-input-field label {
    font-weight: bold;
}

.oq-input-field input {
    transition: 0.27s;
    outline: none;
    border: none;
    border-bottom: 2px solid #ddd;
    font-size: 16px;
    padding: 10px 15px;
}

.oq-input-field input:hover {
    border-color: var(--primary-color);
}

.oq-input-field input:focus {
    background-color: rgb(247, 247, 247);
    border-color: var(--primary-color);
}

.oq-input-field textarea {
    transition: 0.27s;
    outline: none;
    border: none;
    border-bottom: 2px solid #ddd;
    font-size: 16px;
    padding: 10px 15px;
    min-height: 150px;
    resize: none;
}

.oq-input-field textarea:hover {
    border-color: var(--primary-color);
}

.oq-input-field textarea:focus {
    background-color: rgb(247, 247, 247);
    border-color: var(--primary-color);
}
