h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400 !important;
}

h4 {
  font-size: 18px !important;
  line-height: 20px !important;
}

.oq-filter-bg {
  border-bottom: 1px solid rgb(0, 0, 0, 0.1);
  padding: 20px;
}
.mx-3 {
  margin-right: 15px;
}
.input-answer {
  width: 70%;
}
.logo-img {
  position: relative;
  bottom: 25px;
}
.percent-corret {
  background-color: green;
  color: white;
  font-weight: bold;
}
.percent-not-correct {
  background-color: red;
  color: white;
  font-weight: bold;
}
.oq-filter-title {
  color: #afafaf;
  font-size: 18px;
}

.oq-font-white {
  color: white;
}

.oq-font-red {
  color: #df0004;
}

.__react_component_tooltip.place-top {
  left: 300px !important;
}

#radialBar {
  top: 0px !important;
}

.flex {
  display: flex;
}

.delete-notification {
  height: 30px;
}

.oq-color-white {
  color: white;
}

.background {
  background-color: var(--primary-color);
  padding: 10px;
}

.input-name-xlsx {
  width: 70%;
  margin-right: 15px !important;
  background-color: #fff;
  border: 1px solid #ccc;
  height: 42px;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
}

.delete-container {
  width: '100%';
  margin-top: 10px;
}

.delete-text {
  font-size: 12px;
}

.modal-gamification {
  padding: 15px 10px !important;
}

.delete-image-terms {
  margin-top: -19px !important;
  margin-left: 100px;
}

.ranking-players {
  float: right;
  text-decoration: underline !important;
  color: var(--primary-color);
}

.recharts-tooltip-item-list > .recharts-tooltip-item:first-child {
  font-weight: bold;
}

.recharts-tooltip-wrapper {
  width: 234px;
  z-index: 9999999;
}

.recharts-tooltip-wrapper div {
  white-space: inherit !important;
  z-index: 9999999;
}

.btn-oq-no-color {
  color: var(--primary-color);
  cursor: pointer;
  text-transform: uppercase;
  font-family: var(--oq-font-book);
  border: 1px solid var(--primary-color);
}

.btn-oq-no-color:hover,
.btn-oq-no-color:active {
  color: var(--secondary-color);
  background-color: var(--primary-color);
}

.active-btn {
  color: var(--secondary-color);
  background-color: var(--primary-color);
}

.conversion-btn {
  padding: 6px 9px !important;
}

.faq-prev {
  display: 'flex';
  flex-direction: column;
}

#btn-add {
  max-width: 150px;
  margin-bottom: 16px;
}

.select-dashboard {
  /* width: 25%; */
  width: 20rem;
  margin-left: 2%;
  margin-bottom: 5px;
}

.datepicker-dashboard {
  width: 15%;
  height: 35px;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 10px;
  margin-top: 8px;
  padding: 15px;
  border-radius: 4px;
  border: solid;
  border-width: 1px;
  border-color: #cccccc;
}

.div-btn-dashboard {
  display: flex;
  margin-left: -19.3%;
}

.label-datapicker-dashboard {
  color: #acadad;
}

.btn-dashboad {
  margin-left: 18%;
  margin-bottom: 15px;
}

/* Customizando Card de graficos */

.card-chart {
  display: flex;
  display: -webkit-flex;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 20px;
}

.box-chart {
  height: 200px;
  margin-top: 20px;
}

.area-loading {
  display: flex;
  min-height: 200px;
  align-items: center;
  justify-content: center;
}

/* Fim Customizando Card de graficos */

.name-palyer-ranking {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.daterow {
  padding-left: 15px;
  padding-right: 15px;
}

#quiz,
#informational,
#convite,
#presencial,
#virtual {
  left: 0px !important;
}

.delete-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.actim {
  height: 205px !important;
}

.question-container {
  width: 100% !important;
  height: 10%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 12px;
}

.question-container.faq {
}

.question {
  margin-left: 2%;
}

.question h3 {
  font-weight: bold;
}

.answer {
  margin-left: 2%;
}

.season-pic {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.action-ranking {
  margin: 0 0 15px 0;
}

.rounded-circle {
  border-radius: 50% !important;
}

.oq-card {
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
  padding: 20px;
  margin: 20px;
  border-radius: 3px;

  /* background-color: #fff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14) !important;
  padding: 15px;
  margin: 15px;
  border-radius: 6px; */
}

.seeSeasonBtn {
  height: fit-content;
  font-size: 16px;
  margin-bottom: 1.25rem;
}

.seasonRankHead {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  align-items: center;
}

.banner-gamification {
  height: 13em;
  width: 13em;
  /* border-radius: 100%; */
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
}

.oq-card-container {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.oq-card-title {
  color: #90a3b9;
  font-size: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.oq-card-title-action {
  color: #90a3b9;
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.input-oq {
  outline-color: #9d9c9c;
  border-color: #9d9c9c;
  border-radius: 3px;
}

.input-oq:focus {
  outline-color: var(--primary-color);
  border-color: var(--primary-color);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px var(--primary-color);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px var(--primary-color);
}

.input-oq::placeholder {
  color: #d1d1d1;
}

.oq-primary-color {
  color: var(--primary-color) !important;
}

.oq-primary-color-blue {
  color: blue !important;
}

.oq-line {
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
}

.row-fluid.oq-padding-vertical.faq {
}

.rc-checkbox-checked .rc-checkbox-inner {
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

.rc-checkbox:hover .rc-checkbox-inner,
.rc-checkbox-input:focus + .rc-checkbox-inner {
  border-color: var(--primary-color) !important;
}

.background-gradient {
  position: absolute;
  min-height: 100%;
  width: 100%;
  background-size: cover;
  background: rgb(20, 20, 20) !important;
  background: linear-gradient(
    130deg,
    rgba(20, 20, 20, 1) 0%,
    rgba(30, 30, 30, 1) 30%,
    rgba(40, 40, 40, 1) 60%,
    rgba(55, 55, 55, 1) 90%
  ) !important;
}

#main-img {
  margin-top: 40%;
}

@media (min-width: 900px) {
  .vertical-line {
    height: 80vh;
    border-left: 2px solid;
  }

  .card-dashboard-info {
    height: 150px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14) !important;
    padding: 7px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
  }

  .card-dashboard-title {
    font-family: var(--oq-font-light);
    font-size: 9pt;
    color: rgb(100, 100, 100);
  }

  .card-chart-title {
    font-family: var(--oq-font-light);
    font-size: 9pt;
    color: rgb(100, 100, 100);
  }

  .card-dashboard-count {
    font-family: var(--oq-font-medium);
    margin: 0;
    color: rgb(100, 100, 100);
  }

  .card-dashboard-status-up {
    font-size: 15pt;
    color: #77bb3f;
  }

  .card-dashboard-status {
    font-size: 11pt;
    color: #4a90e2;
  }

  .card-dashboard-status-text {
    font-family: var(--oq-font-medium);
    font-size: 11pt;
    color: #afafaf;
  }

  .card-dashboard-footer {
    margin-top: 10%;
  }
}

@media (max-width: 900px) {
  .vertical-line {
    display: none;
  }

  .vl-small {
    display: none;
  }

  .imgpv {
    padding-left: 30px !important;
  }

  .card-dashboard-info {
    margin-bottom: 5%;
    height: 120px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14) !important;
    text-align: left !important;
    padding: 10px 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
  }

  .card-dashboard-title {
    font-family: var(--oq-font-light);
    font-size: 8pt;
    color: rgb(100, 100, 100);
  }

  .card-dashboard-count {
    font-family: var(--oq-font-medium);
    margin: 0;
    color: rgb(100, 100, 100);
  }

  .card-dashboard-info:nth-child(even) {
    float: right;
  }

  .card-dashboard-status-up {
    font-size: 12pt;
    color: #77bb3f;
  }

  .card-dashboard-status {
    font-size: 10pt;
    color: #4a90e2;
  }

  .card-dashboard-status-text {
    font-family: var(--oq-font-medium);
    font-size: 9pt;
    color: #afafaf;
  }

  .card-dashboard-footer {
    margin-top: 12%;
  }
}

#login-form {
  margin-top: 40%;
}

.login-label {
  color: #696666;
  font-family: var(--oq-font-medium);
  font-size: 13px;
}

.login-placeholder {
  width: 100%;
  font-family: var(--oq-font-book);
  font-size: 18px;
  color: #d8d8d8;
  height: 40px;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #696666;
  padding: 5px;
}

.login-placeholder:focus {
  outline-color: unset !important;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  border-color: unset !important;
  border-bottom-color: #000000 !important;
}

.height-300 {
  height: 300px !important;
}

.height-600 {
  height: 600px !important;
}

.btn-oq-preview {
  color: white;
}

.btn-oq {
  color: var(--secondary-color);
  cursor: pointer;
  text-transform: uppercase;
  font-family: var(--oq-font-book);
  font-size: initial;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
  background-color: var(--primary-color);
  /* background-image: -webkit-linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-dark)
  );
  background-image: -moz-linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-dark)
  );
  background-image: -ms-linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-dark)
  );
  background-image: -o-linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-dark)
  );
  background-image: linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-dark)
  );
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=var(--primary-color), endColorstr=var(--primary-color-dark)); */
}

.btn-oq-disabled {
  color: black !important;
  background-image: linear-gradient(to right, #eee, #eee) !important;
}

.list-documents {
  background-color: red;
}

.m-10 {
  margin: 10px;
}

.w-25 {
  width: 25% !important;
}

.w-35vw {
  width: 35vw !important;
}

.flex-grow {
  flex-grow: 1;
}

.form-row {
  display: flex;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.align-self-end {
  align-self: flex-end;
}

.error-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 15px;
}

.p-left-10 {
  padding-left: 10px;
}

.btn-oq:hover {
  color: #fff !important;
  filter: brightness(85%);
}

.btn-oq:focus {
  color: #fff !important;
}

.btn-oq-lg {
  padding: 15px 10px;
}

.btn-oq-profile {
  font-family: var(--oq-font-light);
  font-size: 130%;
  color: #fff;
  background-color: var(--primary-color);
  width: 100%;
  height: 60px;
  margin-bottom: 45px;
  border: none;
  border-radius: 4px;
}

.btn-oq-action {
  font-family: var(--oq-font-light);
  font-size: 95%;
  color: #fff;
  background-color: var(--primary-color);
  width: 100%;
  border: none;
  border-radius: 4px;
}

.btn-oq-new-action {
  margin-top: 10%;
  margin-bottom: 5%;
  height: 50px;
  font-family: var(--oq-font-light);
  font-size: 110%;
  color: #fff;
  background-color: var(--primary-color);
  width: 100%;
  border: none;
  border-radius: 4px;
}

.profile-label {
  color: #4f4f4f;
  font-family: var(--oq-font-medium);
  font-size: 14px;
}

.profile-placeholder {
  width: 100%;
  font-family: var(--oq-font-book);
  font-size: 14px;
  border: none;
  border-radius: 0px;
  padding: 5px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.1) !important;
  color: #555;
  background-color: #fff;
  background-image: none;
}

.input-pitch {
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.1) !important;
  color: #555;
  font-family: var(--oq-font-book);
  border: none;
  border-radius: 0px;
  padding: 5px;
  margin-bottom: 10px;
  font-size: 14px;
  width: 250px;
}

#password-forget-text {
  font-family: var(--oq-font-light);
  font-size: 120%;
  color: #df0004 !important;
}

#sidebar ul li svg {
  margin-left: 5%;
  margin-right: 5%;
}

#sidebar ul li.active .sidebarText {
  font-family: var(--oq-font-medium);
  font-size: 100%;
  color: #000;
}

.nav-text-blue {
  font-family: var(--oq-font-medium);
  font-size: 100%;
  color: #61778e !important;
}

.nav-text-grey {
  color: #b3b3b3 !important;
}

.clickable {
  cursor: pointer;
}

.vl-small {
  border-left: 1px solid #dadada;
  height: 25px;
}

.subtitle-grey {
  font-family: var(--oq-font-light);
  font-size: 16pt;
  color: #a8a8a8;
}

.select-transparent {
  background: transparent;
}

.profile-title {
  font-family: var(--oq-font-book);
  font-size: 13pt;
  color: #000 !important;
}

.asideButtons {
  display: flex;
  justify-content: flex-end;
}

/* #sidebar ul li.active>a i::before,
#sidebar ul li a:hover i::before {
    background: var(--primary-color);
    background: -moz-linear-gradient(top, var(--primary-color) 0%, var(--secondary-color) 100%);
    background: -webkit-linear-gradient(top, var(--primary-color) 0%, var(--secondary-color) 100%);
    background: linear-gradient(to bottom, var(--primary-color) 0%, var(--secondary-color) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=var(--primary-color), endColorstr=var(--secondary-color), GradientType=0);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
} */

.main-img {
  filter: grayscale(100%);
  opacity: 0.2;
  cursor: pointer;
}

.btn-oq-back {
  color: #4c4c4c;
  background-color: transparent;
  background-image: none;
  border-color: #d7d9d9;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: normal;
  border-radius: 3px;
}

.btn-oq-back:hover {
  color: #4c4c4c;
  background-color: #c3c3c3;
  border-color: #c3c3c3;
}

.btn-oq-back:focus {
  color: #4c4c4c;
  background-color: transparent;
  background-image: none;
  border-color: #c3c3c3;
}

.btn-oq-back:not(:disabled):not(.disabled).active,
.btn-oq-back:not(:disabled):not(.disabled):active,
.show > .btn-oq-back.dropdown-toggle {
  color: #4c4c4c;
  background-color: #c3c3c3;
  border-color: #c3c3c3;
}

.btn-oq-back.focus,
.btn-oq-back:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.oq-padding-top {
  padding-top: 20px;
}

.oq-padding-bottom {
  padding-bottom: 20px !important;
}

.oq-padding-horizontal {
  padding-left: 20px;
  padding-right: 20px;
}

.oq-padding-left {
  padding-left: 20px !important;
}

.oq-padding-right {
  padding-right: 20px !important;
}

.oq-margin-right {
  margin-right: 20px;
}

.oq-margin-left {
  margin-left: 20px;
}

.oq-margin-horizontal {
  margin-right: 20px;
  margin-left: 20px;
}

.oq-padding-vertical {
  padding-top: 20px;
  padding-bottom: 20px;
}

.oq-no-padding-left {
  padding-left: 0px !important;
}

.oq-font-light {
  font-family: var(--oq-font-light) !important;
}

.oq-font-medium {
  font-family: var(--oq-font-medium) !important;
}

.oq-font-big {
  font-family: var(--oq-font-medium) !important;
  font-size: 19px !important;
}

.oq-font-bold {
  font-family: var(--oq-font-bold) !important;
}

.oq-font-book {
  font-family: var(--oq-font-book) !important;
}

.span-small-oq {
  font-size: 10px;
  font-family: var(--oq-font-light);
}

.small-oq {
  font-size: 14px;
  font-family: var(--oq-font-light);
}

.d-flex {
  display: flex !important;
}

.w-100 {
  width: 100% !important;
}

.p-0 {
  padding: 0px !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.float-right {
  float: right !important;
}

.vertical-center {
  min-height: 100%;
  display: flex;
  align-items: center;
}

.h4-oq {
  font-size: 1.7rem !important;
}

.oq-profile {
  padding-top: 20px;
  text-align: center;
}

.oq-profile-picture {
  /* width: 65%; */
  width: 180px;
  height: 180px;
  /* height: auto; */
  object-fit: cover;
  border-radius: 50%;
  -webkit-box-shadow: 1px 1px 16px 1px rgba(0, 0, 0, 0.53);
  -moz-box-shadow: 1px 1px 16px 1px rgba(0, 0, 0, 0.53);
  box-shadow: 1px 1px 16px 1px rgba(0, 0, 0, 0.53);
}

.oq-profile-trophy {
  background-color: var(--primary-color);
  color: white !important;
  border-radius: 50%;
  padding: 2%;
  position: absolute;
  bottom: 50%;
  right: 20%;
  -webkit-box-shadow: 1px 1px 16px 1px rgba(0, 0, 0, 0.53);
  box-shadow: 1px 1px 16px 1px rgba(0, 0, 0, 0.53);
  font-size: 25px;
}

.oq-profile-trophy-player-details {
  background-color: var(--primary-color);
  /* color: yellow; */

  border-radius: 50%;
  position: absolute;
  -webkit-box-shadow: 1px 1px 16px 1px rgba(0, 0, 0, 0.53);
  box-shadow: 1px 1px 16px 1px rgba(0, 0, 0, 0.53);
  font-size: 25px;
  padding: 2%;
  right: 20%;
}

.oq-box {
  padding-top: 40px;
}

.oq-points-box {
  padding: 20px 10px;
  border: 1px solid #afb0b1;
  text-align: center;
  border-radius: 3px;
  width: 75%;
  margin: 0 auto;
}

.oq-points-box-primary {
  padding: 10px;
  border: 1px solid var(--primary-color);
  text-align: center;
  border-radius: 3px;
  margin: 0 auto;
}

.oq-points {
  max-width: 100%;
  font-size: 180%;
  font-weight: bold;
  color: var(--primary-color);
}

.oq-points-desc {
  color: #afb0b1;
  font-size: 12px;
}

.oq-title {
  padding-top: 20px;
  padding-bottom: 20px;
  color: #000;
  font-size: 1.2em;
}

.oq-status-box {
  padding-top: 20px;
}

.oq-status-box-label {
  text-align: center;
  font-size: 12px;
  width: 100%;
}

.oq-status-box-switch {
  text-align: center;
}

/* .btn:hover, .btn:focus, .btn.focus{
    color: #fff !important;
} */

.oq-confirm-modal {
  text-align: center;
  width: 70vw;
  padding: 40px;
  background: #fff;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #000;
}

.btn-oq-black {
  background-color: #353535;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  font-family: var(--oq-font-medium);
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
  border: 0;
}

.btn-oq-black-roles {
  background-color: #353535;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  font-family: var(--oq-font-medium);
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
  border: 0;
  margin-top: 10px;
  height: 30px;
}

.btn-oq-black:hover {
  color: #fff;
  background-color: #0e0000;
}

.swiper-pagination-bullet-active {
  background-color: #fff !important;
}

.swiper-pagination-bullet {
  background-color: #fff !important;
}

.swiper-scrollbar-drag {
  background: rgba(255, 255, 255, 0.5) !important;
}

.swiper-button-prev,
.swiper-button-next {
}

.fa-swiper {
  position: absolute;
  top: 50%;
  color: #fff;
  font-size: 2.5em;
  position: absolute;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
}

.fa-swiper-right {
  right: 3%;
  left: auto;
}

.fa-swiper-left {
  left: 3%;
  right: auto;
}

.span-oq-title {
  color: #6e7e90;
  font-family: var(--oq-font-medium);
  font-size: x-large;
}

.span-oq-time {
  color: #6e7e90;
  font-family: var(--oq-font-medium);
  font-size: initial;
}

.span-oq-day {
  color: #474747;
  font-size: 1.4em;
  line-height: 0 !important;
  font-weight: bold;
}

.btn-oq-label {
  font-size: 20px;
  color: #8ea1b7;
  vertical-align: middle;
}

.link-oq {
  color: #999 !important;
}

.oq-datasheet-link {
  padding-top: 20px;
  text-align: center;
  text-decoration: underline !important;
  font-size: initial;
}

.span-oq {
  color: #8ea1b7;
  font-family: var(--oq-font-book);
  font-size: large;
  text-align: justify;
}

.span-oq-desc {
  color: #8ea1b7;
  font-family: var(--oq-font-book);
  font-size: inherit;
  text-align: justify;
}

.span-oq-bold {
  color: #8ea1b7;
  font-family: var(--oq-font-book);
  font-size: 14px;
  font-weight: bold;
}

legend {
  border: 1px solid #e5e5e5;
  padding: 3px 6px;
  border-radius: 6px;
}

fieldset {
  padding: 0.35em 0.625em 0.75em !important;
  margin: 0 2px !important;
  border: 1px solid #c0c0c0 !important;
}

.__react_component_tooltip {
  z-index: 9999999 !important;
}

i.glyphicon-custom-icon::before {
  transition: all 0.9s;
}

#navbar.active i.glyphicon-custom-icon::before {
  content: '\e258';
}

#navbar i.glyphicon-custom-icon::before {
  content: '\E257';
}

.react-tagsinput-tag {
  background-color: #aaaab9 !important;
  color: black !important;
  border: 1px solid black !important;
}

.react-tagsinput-tag a::before {
  color: red !important;
}

.oq-min-v-h-100 {
  min-height: 100vh !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.profile-pic {
  background-size: cover;
  border-radius: 50% 50% 50% 50%;
  background-position: center;
  width: 20vh;
  height: 20vh;
}

.form-group {
  margin-bottom: 20px;
}

.form-inline .form-group {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-space-evenly {
  justify-content: space-evenly !important;
}

.h-100 {
  height: 100% !important;
}

.oq-border-secondary-contrast-color {
  border: 1px solid #6e7e90;
}

.link-oq a {
  color: #999 !important;
}

.swatches-picker div div div {
  margin-bottom: 2.2%;
}

.oq-card-span {
  font-family: var(--oq-font-medium);
  font-size: 12px;
  color: #464646;
}

.oq-grayscale {
  filter: grayscale(100%) opacity(60%);
}

.oq-grayscale#logo {
  filter: grayscale(100%) brightness(150%) !important;
}

.oq-copyright-text {
  font-size: 9px !important;
  font-family: var(--oq-font-light);
}

.sketch-picker {
  height: 32em;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 10px, rgba(0, 0, 0, 0.16) 0px 2px 5px !important;
}

.m-bottom-10 .sketch-picker {
  height: 24em;
}

.react-confirm-alert-overlay {
  z-index: 999999999999 !important;
}

.profile-add-on {
  font-family: var(--oq-font-book);
  font-size: 14px;
  border: none;
  border-radius: 0px;
  padding: 5px;
  -webkit-box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.1) !important;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.1) !important;
  background-color: #fff;
}

.edit-profile-pic {
  position: absolute;
  top: 0;
  right: -20px;
}

.edit-profile-pic-icon {
  background: var(--primary-color);
  padding: 10px;
  border-radius: 50%;
  color: var(--secondary-color);
  box-shadow: 3px 3px 5px 0px rgba(130, 130, 130, 1);
}

.oq-no-padding {
  padding: 0px !important;
}

.custom-modal {
  max-width: 85% !important;
}

.custom-modal-docs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 30% !important;
}

.text-muted {
  color: red;
}

.card {
  min-height: 150px !important;
}

.banner-cover {
  background-size: cover;
}

.oq-action-card {
  background: #fff;
  padding: 10px;
  position: relative;
  margin-top: -3px;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.oq-action-card-container .btn {
  box-shadow: unset;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  font-size: 1rem;
}

.oq-action-card-divider {
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  margin: auto 18px;
}

.oq-action-card-title {
  margin-top: 8%;
  font-weight: bold;
}

.banner-overlay {
  width: 100%;
  height: 100%;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.bold {
  /* font-weight: bold; */
  font-family: var(--oq-font-bold);
}

.grayText {
  color: #9d9c9c;
}

.tipText {
  color: #9d9c9c;
  font-size: 12px;
}

.-cursor-pointer {
  cursor: pointer !important;
  border-right: 0px solid #90a3b9 !important;
  margin: 1px !important;
  font-size: initial;
}

.no-margin {
  margin: 0px !important;
}

.copy-button {
  align-items: center;
  padding-top: 4vh;
}

.oq-obersvation {
  font-size: 0.8em;
  margin-right: 3vw;
}

.row-observation {
  display: flex;
  justify-content: flex-end;
}

.graph-icon {
  color: #e86868;
}

.graph-modal {
  width: 70vw;
  height: 60vh;
}

.select-wrapper {
  display: flex;
  justify-content: flex-end;
}

.range-selecter {
  width: 15vw;
}

.terms_img {
  width: 5vh;
}

.p-0-right {
  padding-right: 0;
}

.points-by-share {
  align-items: flex-start;
  display: flex;
}

.info-icon {
  font-size: 14px;
}

.arrow-toggle {
  transition: all 1s ease-in-out;
}

.arrow-toggle:after {
  font-family: 'Glyphicons Halflings';
  content: '\e114';
  float: right;
  color: gray;
}

.arrow-toggle.collapsed:after {
  content: '\e080';
}

.public-type {
  align-items: flex-end;
  display: flex;
}

.align-items-flex-end {
  display: flex;
  align-items: flex-end;
}

.uncompleted-form {
  color: red;
  font-size: 11px;
  margin-left: 1vw;
}

.gamification-title {
  color: #4f4f4f;
  font-family: var(--oq-font-medium);
  font-size: 14px;
}

.gamification-btns-wrapper {
  justify-content: center;
  display: flex;
}

.tool-tip {
  max-width: 33vw;
}

.hr-margin {
  margin-right: 17.5%;
}

.m-bottom-20 {
  margin-bottom: 20px;
}

.m-top-20 {
  margin-top: 20px;
}

.m-bottom-10 {
  margin-bottom: 10px;
}

.p-right-0 {
  padding-right: 0;
}

.m-0 {
  margin: 0 !important;
}

.m-bottom-7vh {
  margin-bottom: 7vh;
}

.conversionsFrom {
  margin-right: 10px;
  margin-bottom: 0;
}

.edit-password-and-delete {
  display: flex;
  text-align: justify;
}

.edit-password-center {
  justify-content: space-evenly;
}

.modal-password {
  width: 36vw;
}

.center-cell {
  justify-content: center;
}

.gray-text {
  color: #d1d1d1;
}

.homescreen-fix-pwa {
  margin-left: 10vw;
}

.graph-wrapper {
  width: 100%;
  height: 60%;
  padding-top: 15px;
}

.legends-wrapper {
  margin-top: 10px;
  height: 35%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.label-wrapper {
  width: 27%;
  display: flex;
  align-items: center;
}

.label-icon {
  width: 5px;
  height: 5px;
  background: red;
  margin-right: 2px;
  border-radius: 5px;
}

.label-text {
  font-size: 0.8em;
  margin: 0;
  line-height: 1.5em;
}

.graph-message {
  font-size: 0.9em;
  display: flex;
  justify-content: center;
}

.option-text {
  margin: 0;
  background: transparent;
  color: white;
  font-size: 15px;
}

.m-bottom-4 {
  margin-bottom: 1.5rem !important;
}

.question-otpion {
  width: 2.3rem;
  height: 2.3rem;
  margin-right: 0.8rem;
  background: #bfc6cc;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 4px;
}

.question-choosen-otpion {
  width: 2.3rem;
  height: 2.3rem;
  margin-right: 0.8rem;
  background: var(--primary-color);
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 4px;
}

.question-span-oq-title {
  color: #6e7e90;
  font-family: var(--oq-font-medium);
  font-size: large;
}

.question-span-oq {
  color: #8ea1b7;
  font-family: var(--oq-font-book);
  font-size: initial;
  text-align: justify;
}

.question-line {
  width: 100%;
  height: 1px;
  background: #bfc6cc;
  margin: 20px 0;
  margin-top: 3rem;
}

.slider-image {
  height: 25vw;
  object-fit: cover;
}

.share-text {
  font-size: 20px !important;
}

#share-now {
  color: var(--primary-color);
}

.action-slider {
  height: 25vw;
}

.swiper-container {
  margin: 84px auto !important;
}

.oq-profile {
  padding-top: 20px;
  text-align: center;
}

.uppercase {
  text-transform: uppercase !important;
}

.oq-profile-picture {
  width: 10em;
  height: 10em;
  border-radius: 50%;
  -webkit-box-shadow: 1px 1px 16px 1px rgba(0, 0, 0, 0.53);
  -moz-box-shadow: 1px 1px 16px 1px rgba(0, 0, 0, 0.53);
  box-shadow: 1px 1px 16px 1px rgba(0, 0, 0, 0.53);
}

.oq-profile-picture.new-height {
  width: 7em !important;
  height: 7em !important;
}

.btn-oq-indications {
  background-color: #fff;
  color: #383838;
  border: 1px solid var(--primary-color);
}

.oq-number {
  height: 32px;
  width: 32px;
  border: 2.2px solid #fff;
  background-color: var(--primary-color);
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

.oq-number span {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  font-weight: bold;
}

.oq-background-white {
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 1px 1px 7px rgb(97, 97, 97);
  padding-bottom: 20px;
}

.check-oq {
  margin-right: 5px;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: var(--primary-color) !important;
}

.col-md-6.justify-content-left.span-oq {
  color: #000;
  font-weight: bold;
  font-size: 1rem;
}

.col-md-6.text-right.span-oq {
  color: #000;
  font-weight: bold;
  font-size: 1rem;
}

.row.justify-content-center.bold span {
  color: var(--primary-color);
}

.oq-title-question {
  background: #353535;
  padding: 1%;
}

.oq-circle-progress {
  width: 14%;
  margin: auto;
}

.oq-percent {
  font-size: 2rem;
  font-weight: bold;
  color: var(--primary-color);
}

.oq-question {
  color: #fff;
  padding-top: 3px;
}

.oq-datasheet-link {
  padding-top: 20px;
  text-align: center;
  text-decoration: underline;
}

.oq-datasheet-link .fa {
  padding-right: 40px;
}

.btn-oq-label {
  font-size: 20px;
  color: #8ea1b7;
  vertical-align: middle;
}

.span-oq {
  color: #8ea1b7;
  font-family: var(--oq-font-book);
  font-size: 14px;
  text-align: justify;
}

.span-oq-create-action {
  color: #fff;
  font-family: var(--oq-font-book);
  font-size: 10px;
  text-align: justify;
}

.wpp-social {
  justify-content: flex-end;
  align-items: baseline;
}

.filds-mandatory {
  color: red !important;
  font-size: 10px;
}

.span-oq-bold {
  color: #8ea1b7;
  font-family: var(--oq-font-book);
  font-size: 14px;
  font-weight: bold;
}

.span-oq-title {
  color: #4d4d4d;
  font-family: var(--oq-font-medium);
  margin-top: 3px;
}

.link-oq {
  color: #999999;
}

body {
  font-family: var(--oq-font-book) !important;
}

.seeSeasonBtn {
  height: fit-content;
}

.seasonSearchBox {
  width: 50%;
  margin: 0 auto;
}

.btn-oq {
  color: var(--secondary-color);
  cursor: pointer;
  text-transform: uppercase;
  font-family: var(--oq-font-book);
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
  background-color: var(--primary-color);
  /* background-image: -webkit-linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-dark)
  );
  background-image: -moz-linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-dark)
  );
  background-image: -ms-linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-dark)
  );
  background-image: -o-linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-dark)
  );
  background-image: linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-dark)
  );
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=var(--primary-color), endColorstr=var(--primary-color-dark)); */
}

body.swal2-shown > [aria-hidden='true'] {
  filter: blur(8px);
  transition: ease-in-out;
}

.white {
  color: white;
}

#animationWrapper {
  height: 90vh;
  min-width: 60vw;
  padding: 0;
  margin: 0;
  z-index: -1;
  background-color: #fff;
}

.footerSWAL {
  font-weight: bolder !important;
  color: #55acee !important;
}

.footerSWAL a {
  text-decoration: underline;
}

.copy-icon {
  height: 40px;
  min-width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none !important;
  z-index: 1;
}

.valid-wrap {
  background-color: rgba(235, 235, 255, 0.5);
  border-radius: 20px;
  cursor: pointer;
}

.qrcode-wrapper {
  width: 30%;
  justify-content: center;
  margin: auto;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.code-background {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 2% 2%;
}

.qrcode {
  margin: 1% 0 !important;
}

/* .rs-steps.rs-steps-small.rs-steps-horizontal{
  width: 60% !important;
} */

.wrap-qrcode {
  align-items: center;
  justify-content: space-evenly;
  background-color: ghostwhite;
  border-radius: 20px;
  margin: 15px 30px 0 30px;
  width: 90%;
  align-self: center;
  padding: 3%;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
}

.qrcode-container {
  width: 100%;
  height: 100%;
  border: 5px solid black;
  display: flex;
  flex-direction: column;
  border-bottom: none;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.qrcode-mid {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: -5px;
  z-index: 1;
}

.qrcode-bottom {
  margin-top: -5px;
  height: 100px;
  width: 100%;
  border-top: none;
  border-bottom: 5px black solid;
  border-right: 5px solid black;
  border-left: 5px solid black;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.dashed-line {
  width: 100%;
  height: 0;
  margin: 5px 0;
  border-top: #000 dashed 2.5px;
  border-bottom: #000 dashed 2.5px;
}

.qrcode-square1 {
  width: 15px;
  height: 30px;
  display: inline-block;
  border-right: 5px solid black;
  border-top: 5px solid black;
  border-bottom: 5px solid black;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
}

.qrcode-square2 {
  width: 15px;
  height: 30px;
  display: inline-block;
  border-left: 5px solid black;
  border-top: 5px solid black;
  border-bottom: 5px solid black;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
}

.qrcode-cupon-up {
  display: flex;
  flex-direction: column;
  height: 35%;
}

.qrcode-img {
  max-width: 100%;
  min-height: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  z-index: 0;
}

.qrcode {
  margin: 1% auto;
}

.btn-oq:focus {
  color: #fff;
  /* background-color: #9e000d; */
  filter: brightness(65%);
}

.btn-oq:not([disabled]):hover {
  color: #fff;
  /* background-color: #9e000d; */
  filter: brightness(65%);
}

.btn-oq:disabled {
  cursor: not-allowed !important;
}

.btn-oq-black,
.btn-oq-black:active,
.btn-oq-black:focus {
  background-color: #353535;
  color: var(--secondary-color);
  cursor: pointer;
  text-transform: uppercase;
  font-family: var(--oq-font-medium);
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
}

.btn-oq-black:hover {
  color: #fff;
  /* background-color: #0e0000; */
  filter: brightness(65%);
}

.oq-twitter {
  background-color: #55acee;
  color: #fff;
}

.oq-facebook {
  background-color: #3b5998;
  color: #fff;
}

.oq-fa {
  padding: 20px;
  max-width: 50px;
}

.oq-whatsapp {
  background: #57bb63;
  color: #fff;
}

.oq-google-plus {
  background-color: #e02f2f;
  color: #fff;
}

.oq-linkedin {
  background-color: #007bb5;
  color: #fff;
}

.btn-oq-back {
  color: #4c4c4c;
  background-color: transparent;
  background-image: none;
  border-color: #d7d9d9;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: normal;
  border-radius: 3px;
}

.btn-oq-back:hover {
  color: #4c4c4c;
  background-color: #c3c3c3;
  border-color: #c3c3c3;
}

.btn-oq-back:focus {
  color: #4c4c4c !important;
  background-color: transparent;
  background-image: none;
  border-color: #c3c3c3;
}

.btn-oq-back:not(:disabled):not(.disabled).active,
.btn-oq-back:not(:disabled):not(.disabled):active,
.show > .btn-oq-back.dropdown-toggle {
  color: #4c4c4c;
  background-color: #c3c3c3;
  border-color: #c3c3c3;
}

.btn-oq-back.focus,
.btn-oq-back:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-oq-terciary {
  color: white;
  background-color: rgb(126, 126, 126);
  background-color: rgb(126, 126, 126);
  padding-left: 20px;
  padding-right: 20px;
  font-weight: normal;
  border-radius: 3px;
  text-transform: uppercase;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
}

.btn-oq-terciary:hover {
  color: white;
  background-color: #9b9b9b;
  border-color: #9b9b9b;
}

span.clickable.btn {
  margin: 0px 1%;
}

.oq-padding-top {
  padding-top: 20px;
}

.oq-margin-top {
  margin-top: 3vh;
}

.oq-padding-bottom {
  padding-bottom: 20px !important;
}

.oq-padding-horizontal {
  padding-left: 20px;
  padding-right: 20px;
}

.oq-padding-left {
  padding-left: 20px;
}

.oq-padding-right {
  padding-right: 15px;
}

.oq-margin-right {
  margin-right: 20px;
}

.oq-margin-left {
  margin-left: 20px;
}

.oq-margin-bottom {
  margin-bottom: 20px;
}

.oq-margin-vertical {
  margin-bottom: 20px;
  margin-top: 20px;
}

.oq-margin-vertical-card-dashboard {
  margin-bottom: 100px;
  margin-top: 100px;
}

.oq-margin-horizontal {
  margin-bottom: 20px;
  margin-top: 20px;
}

.oq-padding-vertical {
  padding-top: 20px;
  padding-bottom: 20px;
}

.oq-no-padding-left {
  padding-left: 0px !important;
}

.oq-no-padding-right {
  padding-right: 0px !important;
}

.oq-float-right {
  float: right;
}

.oq-action-card {
  background: #fff;
  padding: 10px;
  position: relative;
  margin-top: -3px;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.card-clickable {
  transition: ease 0.5s !important;
}

.card-clickable:hover {
  transform: translateY(-2px);
}

.oq-action-card-container .btn {
  box-shadow: unset;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  font-size: 1rem;
}

.oq-action-card-divider {
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  margin: auto 18px;
}

.oq-action-card-info {
  min-height: 72px;
}

.oq-action-card-title {
  position: relative;
}

.oq-premium-card {
  background: #fff;
  position: relative;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.cepBtn {
  height: fit-content;
  outline: 0;
}

.cepWrap {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.premium-card-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding-top: auto;
}

.span-oq-day {
  color: #474747;
  font-size: 1em;
  line-height: 0px !important;
}

.span-oq-month {
  color: var(--primary-color);
  text-transform: uppercase;
  line-height: 0px !important;
}

.small-oq {
  font-size: 14px;
  margin: 0px 20px;
  font-family: var(--oq-font-light);
}

.span-small-oq {
  font-size: 10px;
  font-family: var(--oq-font-light);
}

.h5-oq {
  font-size: 1.25rem !important;
}

.h4-oq {
  font-size: 1.5rem !important;
}

.h3-oq {
  font-size: 1.75rem !important;
}

.purchase-item-img {
  width: 50%;
}

.col-md-12 div div .swiper-wrapper {
  height: 69vh;
}

@media (max-width: 768px) {
  .responsive-banner {
    width: 100% !important;
  }

  .oq-hidden-xs {
    display: none;
  }

  .small-oq {
    font-size: 13px;
  }

  .oq-height {
    justify-content: center !important;
  }

  .oq-btn-action {
    margin-left: 0px !important;
    margin-right: 0px;
  }

  .oq-height-action {
    padding-left: 0;
    padding-right: 0;
  }

  .oq-title-share {
    font-size: 4vh;
  }

  .col-md-12 div div .swiper-wrapper {
    animation: fadeMobile 4s;
    height: 31vh;
  }

  .swiper-wrapper {
    animation: divMobile 4s;
  }

  .slider-image {
    height: 100% !important;
    animation: imgMobile 4s;
  }

  .call-action-share {
    margin-left: 0 !important;
    margin-top: 7vh !important;
  }

  .span-share-indication {
    font-size: 3vh !important;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 9.5rem;
  }

  .fa-stopwatch-share {
    font-size: 11vh !important;
    display: grid !important;
  }

  .info-left {
    padding-left: 15px;
  }

  .purchase-item-img {
    width: 70%;
  }

  #photo-share {
    width: 18vh !important;
    height: 18vh !important;
  }

  .notebadge {
    position: absolute;
    height: 16px;
    width: 16px;
    top: 0;
    right: 25% !important;
    font-weight: 500;
    border-radius: 50%;
    background: red;
  }
}

.header-container {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  justify-content: space-between;
}

.cart-board {
  padding-left: 5px;
}

.cart-elm {
  max-width: 80%;
  margin: 20px auto !important;
}

.qtd-input {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 6px;
  color: #666;
  width: 50px;
  text-align: center;
}

.itemName {
  display: flex;
  flex: 1;
  width: auto;
}

.itemQtd {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.cartList-item {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.scoreBlc-text {
  line-height: 1 !important;
}

.backBtns-container,
.filtersContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 88%;
  margin: 0 auto;
}

.premiumHeader-wrappper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
}

.premiumHeader-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}

.premium-header {
  display: flex;
  flex-direction: column;
}

.pHeader3 {
  text-align: right;
}

.premiumsList {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  max-width: 90%;
  width: 90%;
  margin: 20px auto;
  /* background-color: #333; */
}

.premium-card-container {
  min-height: 50vh;
  padding: 15px;
}

.premium-card-container:first-child {
  margin-left: 0;
}

.premium-card-container:last-child {
  margin-right: 0;
}

.premium-card {
  padding: 0 20px 20px;
  min-width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.35);
}

.small-available-container {
  margin-right: -10px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 5px;
  align-self: flex-end;
  color: #fff;
  padding: 4px 10px 4px 10px !important;
}

.cardHeader-items {
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.oq-vertical-align {
  display: table-cell;
}

.oq-vertical-align span {
  vertical-align: sub;
}

.circle {
  border: 2px solid #fff;
  background-color: var(--primary-color);
  border-radius: 50%;
  position: relative;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.circle span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

.question-line-answer {
  width: 100%;
  height: 1px;
  background: #bfc6cc00;
  margin: 10px 0;
}

.question-wrapper-title {
  background-color: #383838;
  vertical-align: inherit;
  flex-direction: row;
  padding: 4vh 7px;
}

.oq-finished-quiz {
  background: #61e46b !important;
}

.oq-finished-quiz-wrong {
  background: rgb(221, 76, 76) !important;
}

.d-flex.my-1 {
  background: #fff;
  padding: 1%;
  box-shadow: 1px 3px 3px #dedede;
}

.right {
  background-color: #0b881e;
}

.wrong {
  background-color: red;
}

.oq-progress {
  background-color: #c5c5c5;
  height: 21px;
  border-radius: 0.2rem;
}

.oq-progress-bar {
  background-color: var(--primary-color);
}

.oq-color-background {
  font-weight: bold;
  padding: 16px;
  /* box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5); */
  background-color: var(--primary-color);
  background-image: -webkit-linear-gradient(
    var(--primary-color),
    var(--primary-color-dark)
  );
  background-image: -moz-linear-gradient(
    var(--primary-color),
    var(--primary-color-dark)
  );
  background-image: -ms-linear-gradient(
    var(--primary-color),
    var(--primary-color-dark)
  );
  background-image: -o-linear-gradient(
    var(--primary-color),
    var(--primary-color-dark)
  );
  background-image: linear-gradient(
    var(--primary-color),
    var(--primary-color-dark)
  );
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=var(--primary-color), endColorstr=var(--primary-color-dark));
}

.oq-timer-quiz {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0 15px;
}

.line-vertical {
  border-left: 3px solid #fff;
  height: 64%;
  margin: auto -3px;
  z-index: 999;
}

.hr {
  border-left: 2px solid rgb(150, 150, 150);
  height: 33px;
  margin: auto 3px;
}

.oq-points-quiz {
  width: 25%;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 2px;
  padding-bottom: 5px;
}

.add-button {
  position: absolute;
  top: 1px;
  left: 1px;
}

.cardDetails-btn,
.cardDetails-btn:active {
  text-decoration: none;
  border: 0;
  padding: 0;
  font-family: var(--oq-font-medium);
  font-size: 14px;
  outline: 0 !important;
  background: none !important;
}

.inStock-small {
  color: #0b881e;
  font-family: var(--oq-font-medium);
  font-weight: 400;
}

.premium-img {
  width: 60%;
  margin-bottom: 15px;
}

.premium-title-container {
  width: 100%;
  margin-bottom: 5px;
}

.premium-title {
  font-family: var(--oq-font-medium);
}

.premium-desc-container {
  margin-bottom: 5px;
}

.premium-desc {
  font-family: var(--oq-font-light);
  color: #838383;
  font-size: 14px;
  line-height: 1.5em !important;
  margin-bottom: 0 !important;
}

.premium-price-container {
  align-self: flex-start;
  margin: 5px 0 15px;
}

.premium-price {
  font-family: var(--oq-font-bold);
  text-transform: uppercase;
  font-size: 21px;
}

.premium-btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: auto;
  border: 0;
  border-radius: 4px;
  padding: 0;
  outline: 0 !important;
}

.premium-btn:active,
.premium-btn:focus,
.premium-btn:visited {
  outline: 0 !important;
  border: none;
  box-shadow: none !important;
  filter: none;
}

.premiumBtn-left-div {
  display: flex;
  flex-direction: row;
  padding: 12px 12px 10px;
  /* background: rgba(255, 255, 255, 0.1); */
  background: rgba(0, 0, 0, 0.05);
  align-items: center;
  font-size: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

#fa-btn {
  margin-top: -3px;
}

.addToCart-btn-text {
  flex: 1;
  text-align: center;
  font-size: 15px;
  font-family: var(--oq-font-medium);
  line-height: 1;
}

/* end of cards */
.oq-card {
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
  padding: 20px;
  margin: 20px;
  border-radius: 3px;
}

.oq-card-container {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.oq-card-title {
  color: #90a3b9;
  font-size: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.oq-card-span {
  font-family: var(--oq-font-medium);
  font-size: 12px;
  color: #464646;
}

.oq-font-light {
  font-family: var(--oq-font-light) !important;
}

.oq-font-medium {
  font-family: var(--oq-font-medium) !important;
}

.oq-font-bold {
  font-family: var(--oq-font-bold) !important;
}

.oq-font-book {
  font-family: var(--oq-font-book) !important;
}

.oq-card-text-muted {
  color: #adadad !important;
}

.oq-line {
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  padding-top: 5px;
  padding-bottom: 5px;
}

.oq-profile-header-bg {
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 40vh;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.oq-profile-picture-container {
  display: flex;
  align-items: center;
  width: 20vh;
  height: 0vh;
  z-index: 2;
}

.oq-profile-header-picture {
  width: 20vh;
  height: 20vh;
  border-radius: 50%;
  bottom: 10vh;
  border: 1px solid #afb0b1;
  object-fit: cover;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.oq-profile-trophy {
  background-color: var(--primary-color);
  /* color: yellow; */
  border-radius: 50%;
  -webkit-box-shadow: 1px 1px 16px 1px rgba(0, 0, 0, 0.53);
  box-shadow: 1px 1px 16px 1px rgba(0, 0, 0, 0.53);
  font-size: 25px;
}

.oq-profile-goal {
  border-radius: 50%;
  padding: 9px;
  margin-right: 0.5vw;
  border: 2px solid;
}

.goal-circles-wrapper {
  justify-content: center;
}

.editProfile {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.editProfileText {
  cursor: pointer;
  margin-right: 10px;

  line-height: 14px;
}

@media (max-width: 575.98px) {
  .oq-profile-header-bg {
    height: 25vh;
  }

  .question-wrapper-title {
    padding: 2vh 7px;
  }

  .circle {
    width: 50px;
    height: 50px;
  }

  .oq-points {
    width: 100%;
  }

  .profile-header-overlay {
    height: 25vh !important;
  }

  .oq-profile-trophy {
    bottom: 2vh;
    left: 24%;
  }

  .right-info-action {
    height: auto !important;
    padding-top: 15px;
  }

  .action-slider {
    height: auto !important;
  }

  .slider-image {
    height: 50vw !important;
  }

  .col-md-10 {
    padding-right: 5px;
    padding-left: 5px;
  }

  #photo-share {
    width: 16vh !important;
    height: 16vh !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .oq-profile-header-bg {
    height: 25vh;
  }

  .question-wrapper-title {
    padding: 2vh 7px;
  }

  .btn-oq-conteudo {
    font-size: 0.8rem;
    line-height: 1.3;
    padding: 1.2vh 0vh;
  }

  .oq-div-conteudo {
    bottom: 84%;
  }

  .circle {
    width: 50px;
    height: 50px;
  }

  .oq-points {
    width: 100%;
  }

  .oq-profile-trophy {
    left: 27%;
  }

  .right-info-action {
    height: auto !important;
    padding-top: 15px;
  }

  .action-slider {
    height: auto !important;
  }

  .slider-image {
    height: 50vw !important;
  }
}

@media (min-width: 768px) {
  .col-md-6 {
    max-width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .oq-profile-header-bg {
    height: 25vh;
  }

  .question-wrapper-title {
    padding: 2vh 7px;
  }

  .oq-points {
    width: 100%;
  }

  .btn-oq-conteudo {
    font-size: 0.8rem;
    line-height: 1.3;
    padding: 1.2vh 0vh;
  }

  .oq-div-conteudo {
    bottom: 84%;
  }

  .oq-profile-trophy {
    left: 34%;
  }

  .right-info-action {
    height: auto !important;
    padding-top: 15px;
  }

  .action-slider {
    height: auto !important;
  }

  .slider-image {
    height: 50vw !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .oq-profile-header-bg {
    height: 25vh;
  }

  .oq-profile-trophy {
    left: 39%;
  }
}

@media (min-width: 1200px) {
}

.oq-box {
  padding-top: 20px;
}

@media (max-width: 767.98px) {
  .oq-box {
    padding-top: 0px;
  }

  .question-wrapper-title {
    padding: 2vh 7px;
  }

  .oq-points {
    width: 100%;
  }

  .btn-oq-conteudo {
    font-size: 0.8rem;
    line-height: 1.3;
    padding: 1.2vh 0vh;
  }

  .oq-div-conteudo {
    bottom: 84%;
  }

  .right-info-action {
    height: auto !important;
    padding-top: 15px;
  }

  .action-slider {
    height: auto !important;
  }

  .slider-image {
    height: 50vw !important;
  }

  .btnsDiv {
    flex-direction: column !important;
    width: auto !important;
  }

  .editProfile {
    padding-bottom: 40px;
  }

  .name-wrapper {
    padding-top: 60px !important;
    flex-direction: column-reverse !important;
  }

  .searching-img {
    width: 60vw;
  }

  .premiumsList {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    width: 100%;
    align-self: center;
  }

  .addToCart-btn-text {
    padding: 0 5px;
  }

  .premiumHeader-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .pHeader1 {
    width: 100vw;
    margin-bottom: 10px;
  }

  .pHeader2 {
    width: 50%;
    margin-bottom: 10px;
  }

  .pHeader3 {
    width: 50%;
    margin-bottom: 10px;
    text-align: right;
  }

  .premium-header {
    display: flex;
    flex-direction: column;
  }

  .premium-btn {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .photo-password-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cepBtn {
    margin: 15px 15px 0;
    outline: 0;
  }

  .cepWrap {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .cart-elm {
    align-self: center;
    max-width: 100vw;
    margin: 20px 0;
  }

  .oq-card {
    padding: 10px;
  }

  .cart-board {
    padding-right: 0;
  }

  .itemQtd {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .qtd-input {
    width: 40px;
  }

  .rescueTitle {
    padding-left: 15px;
  }

  .seasonSearchBox {
    width: 92%;
  }

  .ranking-container {
    width: 95% !important;
    padding: 5px 10px 20px !important;
  }

  .rankingButtons {
    display: flex;
    flex-direction: column !important;
  }

  .scope-buttons-container {
    width: 92% !important;
    flex-wrap: wrap !important;
    justify-content: space-around !important;
  }

  .scopeButton {
    /* margin-right: 20px; */
    margin-bottom: 15px;
  }

  .rankingLoading {
    width: 92% !important;
  }

  .top-three-container {
    width: 100% !important;
  }

  span.top-three-name {
    font-size: 16px !important;
  }

  .silverTrophy,
  .bronzeTrophy {
    height: 80px !important;
    width: auto;
  }

  .goldenTrophy {
    height: 100px !important;
    width: auto;
    margin-top: 10px;
  }

  .regional-title-container {
    width: 60%;
  }

  .regional-title {
    font-size: 14px !important;
    display: flex;
  }

  .rowPosition {
    padding: 0 !important;
    padding-left: 0 !important;
    display: none !important;
  }

  .rowPoints {
    padding-right: 5px !important;
    padding-left: 10px !important;
  }

  /* .regional-logo-container {
    width: ;
  } */

  .position-circle {
    display: block !important;
    position: absolute !important;
    border-radius: 50% !important;
    background-color: #353535 !important;
    color: white !important;
    min-width: auto !important;
    height: auto;
    font-size: 10px;
    padding: 4px;
    z-index: 100;
    margin-top: -5px !important;
    margin-left: -5px !important;
  }

  .regional-logo {
    width: 48px;
    height: auto;
    position: relative;
    margin-right: 10px !important;
  }

  .player-ranking-pic {
    margin-right: 10px !important;
    position: relative;
  }

  .regional-points {
    font-size: 20px !important;
  }

  .rowPts {
    padding-left: 3px !important;
    font-size: 14px !important;
  }
}

.oq-points-box {
  padding: 10px;
  border: 1px solid #afb0b1;
  text-align: center;
  border-radius: 3px;
  margin: 0 auto;
}

.oq-box-secondary {
  padding-top: 0px;
}

.oq-points-box-secondary {
  padding: 10px;
  border: 1px solid var(--primary-color);
  text-align: center;
  border-radius: 3px;
  margin: 0 auto;
}

.oq-points-box-primary {
  padding: 10px;
  border: 1px solid #464646;
  text-align: center;
  border-radius: 3px;
  margin: 0 auto;
}

.ranking-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
  padding: 40px;
  width: 65%;
  margin: 0 auto;
}

.rankingRow {
  display: flex;
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px solid #ddd;
}

.rankingRow:last-child {
  padding-bottom: 0 !important;
  border-bottom: 0;
}

.rowPosition {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  width: auto !important;
  object-fit: contain;
}

.position-circle {
  display: none;
}

.regional-badge {
  width: 22px;
  height: 22px;
  margin-left: 5px;
  z-index: 100;
}

.rowPoints {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 15px;
}

.rowPts {
  line-height: 48px;
  color: #808080;
  padding-top: 5px;
  padding-left: 5px;
}

.action-ranking {
  margin: 0 0 15px 0;
}

.ranking-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}

.top-three-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 45%;
  margin: 40px auto 0;
}

.ranking-pic {
  /* width: 64px;
  height: 64px; */
  border-radius: 50%;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/playerProfile%2Fxx5zRytNqvWsMif4yT85Agcm2xU2%2Fc0120128-2215-4817-9ad1-722cd54ba148.jpg?alt=media&token=38a991c2-d449-46e8-acd7-8a699f5951bf');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.regional-ranking-pic {
  width: 80px;
  height: 80px;
}

.player-top3-pic {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.rankingButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.scope-buttons-container {
  margin: 0 auto;
  width: 50%;
  justify-content: space-around;
  display: flex;
  flex-direction: row;
}

.scopeButton,
.scopeButton:active,
.scopeButton:focus {
  /* margin-left: 30px;
  margin-right: 30px; */
  outline: 0 !important;
  /* border: 0 !important; */
}

.rankingLoading {
  padding: 20px;
  text-align: center;
  width: 60%;
  margin: 0 auto;
}

.gold-pic {
  width: 80px !important;
  height: 80px !important;
}

.regional-gold-pic {
  width: 100px !important;
  height: 100px !important;
}

.podium-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

span.top-three-name {
  margin-top: 10px;
  margin-bottom: 30px !important;
  font-size: var(--oq-font-medium);
  text-align: center;
  padding: 0 5px;
  font-size: 16px;
}

.silverTrophy,
.bronzeTrophy {
  height: 94px;
  width: auto;
}

.goldenTrophy {
  height: 133px;
}

.silver-name {
  /* text-align: left !important; */
}

.bronze-name {
  /* text-align: right !important; */
}

.gold-name {
  font-size: 20px !important;
}

.gold-item {
  margin: 0 20px;
}

.oq-points-box-primary .span-small-oq {
  color: #464646;
}

.oq-points-box-primary .oq-points-desc {
  color: #464646;
}

.oq-points-box-secondary .span-small-oq {
  color: var(--primary-color);
}

.oq-points-box-secondary .oq-points-desc {
  color: var(--primary-color);
}

.oq-points {
  font-size: 25px;
  font-family: var(--oq-font-medium);
  color: var(--primary-color);
}

.oq-points-desc {
  color: #afb0b1;
  font-size: 12px;
}

.oq-points-date {
  color: #000;
}

.oq-points-desc-alt {
  color: #4c4c4c;
}

.btn-oq-lg {
  padding: 15px 10px;
}

.btn-oq-lg-create-template {
  padding: 15px 10px;
}

.border-oq {
  border: 1px solid #61778e;
}

.oq-border {
  border: 1px solid #61778e;
}

.oq-border-secondary-contrast-color {
  border: 1px solid var(--secondary-contrast-color);
}

.list-superscript {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.oq-red {
  color: #000000;
}

.input-oq {
  width: 100%;
  font-family: var(--oq-font-light);
  font-size: 14px;
  border: none;
  border-radius: 0px;
  padding: 5px;
  -webkit-box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.1) !important;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.1) !important;
}

.input-oq::placeholder {
  color: #9da8b4;
  font-family: var(--oq-font-book);
}

.input-oq-label {
  color: #4f4f4f;
  font-family: var(--oq-font-medium);
  font-size: 14px;
}

.input-oq-label span {
  color: rgb(210, 17, 26);
}

.background-gradient {
  background-color: #ffffff;
  min-height: 100vh;
}

#main-img {
  margin-top: 50%;
}

#main-form {
  margin-top: 30%;
}

#main-form-register {
  margin-top: 10%;
}

@media (max-width: 991.98px) {
  #main-img {
    margin-top: 10%;
  }

  #main-form {
    margin-top: 10%;
  }
}

@media (min-width: 900px) {
  .vertical-line {
    height: 80vh;
    border-left: 2px solid #a6a5a5;
    /* margin-top: 10vh; */
  }
}

@media (max-width: 900px) {
  .vertical-line {
    display: none;
  }

  #login-form {
    padding: 20px;
  }

  .small-screen-row {
    margin: 0;
  }

  .small-screen-action-details {
    padding: 0 !important;
    margin-top: -20px !important;
  }

  .wrap-qrcode {
    width: 90% !important;
  }

  .left-qrcode {
    max-width: 50%;
  }

  .qrcode {
    width: 25% !important;
    height: 75% !important;
  }

  .validcode {
    font-size: 1em;
    padding-left: 10px !important;
  }

  .copyLink {
    margin-top: -10px;
  }

  .validText {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.small-screen-action-details {
  margin-top: 1%;
}

.rt-td {
  display: flex;
  align-items: center;
}

.login-label {
  color: #696666;
  font-family: var(--oq-font-medium);
  font-size: 13px;
}

.form-signin input {
  background: transparent;
}

.login-placeholder {
  width: 100%;
  font-family: var(--oq-font-book);
  font-size: 18px;
  height: 40px;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #696666;
  padding: 5px;
}

.btn-oq-login {
  font-family: var(--oq-font-book);
  text-transform: uppercase;
  font-size: 110%;
  color: #fff;
  background-color: var(--primary-color);
  width: 75%;
  height: 50px;
  margin-bottom: 45px;
  border: none;
  border-radius: 4px;
}

.btn-oq-login-preview {
  /* border: 1px solid rgb(4, 123, 36); */
  /* color: #fff; */
  background: none;
  padding-top: 13px;
  width: initial;
  font-family: var(--oq-font-book);
  text-transform: uppercase;
  font-size: 110%;
  width: 75%;
  height: 50px;
  margin-bottom: 45px;
  border: none;
  border-radius: 4px;
}

.login-placeholder:focus {
  outline-color: unset !important;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  border-color: unset !important;
  background-color: transparent !important;
  border-bottom-color: var(--primary-color) !important;
}

#password-forget-text {
  font-family: var(--oq-font-light);
  color: #df0004 !important;
}

.oq-link-decoration {
  text-decoration: underline;
}

.name-wrapper {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
}

.oq-a-link {
  color: #4988d2;
}

.oq-a-passwordLink {
  color: var(--primary-color);
}

.editPasswordText {
  color: var(--primary-color);
  margin: 0 0 0 10px;
  line-height: 14px;
  top: 2px;
}

@media (max-width: 767.98px) {
  .oq-icon-img {
    width: 15% !important;
  }
}

.oq-icon-img {
  width: 20%;
  height: auto;
}

.no-line-break {
  white-space: nowrap;
}

.min-h {
  min-height: 100%;
}

.profile-header-overlay {
  width: 100%;
  height: 40vh;
  background-image: inherit;
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
  border-radius: 0.25rem;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  object-fit: cover;
}

@media (max-width: 991.98px) {
  .oq-profile-follow-row {
    display: none !important;
  }

  .oq-profile-follow-row-mobile {
    display: flex !important;
  }

  .oq-profile-follow {
    padding-top: 8vh;
  }
}

@media (min-width: 992px) {
  .oq-profile-follow-row {
    display: flex !important;
  }

  .oq-profile-follow-row-mobile {
    display: none !important;
  }
}

/*
.dropdown-arrow::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  color: var(--secondary-contrast-color);
} */

.dropdown-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  margin-top: 6px;
  margin-bottom: 5px;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  color: var(--secondary-contrast-color);
}

.profile-pic {
  background-size: cover;
  border-radius: 50% 50% 50% 50%;
  width: 20vh;
  height: 20vh;
  object-fit: cover;
}

.regional-logo {
  border-radius: 0;
  background-size: cover;
  border: 0 !important;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 60px;
  height: auto;
  margin-right: 15px;
}

.regional-title-container {
  display: flex;
  align-items: center;
  flex: 1;
}

.player-title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}

.regional-title {
  font-size: 20px;
}

.regional-points {
  font-size: 28px;
}

.player-ranking-pic {
  background-size: cover;
  border-radius: 50% 50% 50% 50%;
  width: 3em;
  height: 3em;
  object-fit: cover;
  margin-right: 15px;
}

.fiscal-note {
  width: 18vh;
  max-width: 18vh;
}

.purchase-title {
  font-size: 1.5em;
  text-transform: uppercase;
  color: #353535;
}

.logoff-button {
  z-index: 25000 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
}

.oq-confirm-modal {
  text-align: center;
  width: 70vw;
  padding: 40px;
  background: #fff;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #000;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btnsDiv {
  width: 45%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.react-confirm-alert-overlay {
  z-index: 1080;
}

.spinner {
  width: 20px;
  height: 20px;
  position: relative;
  margin: 5px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@keyframes slideOut {
  0%,
  30% {
    opacity: 0;
  }

  40%,
  70% {
    opacity: 1;
    top: 0;
  }

  80%,
  100% {
    opacity: 1;
  }

  /* from {
    opacity: 0;
  }
  to {
    opacity: 1;
  } */
}

.oq-primary-color {
  color: var(--primary-color) !important;
}

.oq-secondary-color {
  color: var(--secondary-color) !important;
}

.oq-primary-contrast-color {
  color: var(--primary-contrast-color) !important;
}

.oq-secondary-contrast-color {
  color: var(--secondary-contrast-color) !important;
}

.oq-min-v-h-100 {
  min-height: 100vh !important;
}

.nav-left {
  max-width: 100%;
}

.nav-leftright {
  flex: 2 0 auto;
}

.navbar .btn-navbar .oq-svg-primary-color path,
.navbar .btn-navbar .oq-svg-primary-color rect,
.footer .btn .oq-svg-primary-color path,
.footer .btn .oq-svg-primary-color rect {
  fill: var(--secondary-contrast-color);
}

.navbar .btn-navbar.active .oq-svg-primary-color path,
.navbar .btn-navbar.active .oq-svg-primary-color rect,
.navbar .btn-navbar:hover .oq-svg-primary-color path,
.navbar .btn-navbar:hover .oq-svg-primary-color rect,
.footer .btn.active .oq-svg-primary-color path,
.footer .btn.active .oq-svg-primary-color rect {
  fill: var(--primary-color);
}

.navbar .btn-navbar.active .oq-grayscale,
.footer .btn.active .oq-grayscale {
  filter: unset;
}

.navbar {
  transition: top 0.3s;
}

.oq-grayscale {
  filter: grayscale(100%) opacity(60%);
}

.recovery-password-modal {
  width: 70vw;
}

.overlayModal {
  background: red;
}

svg.oq-svg-primary-color {
  padding: 1.5px;
}

.oq-font-small {
  font-size: 10px;
}

.oq-profile-trophy-small {
  background-color: var(--primary-color);
  color: yellow;
  border-radius: 50%;
  padding: 6px;
  position: relative;

  /* -webkit-box-shadow: 1px 1px 16px 1px rgba(0, 0, 0, 0.53); */
  /* box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.53); */
  font-size: 12px;
  bottom: -4vh;
}

.circle-oq-quiz {
  border-radius: 50%;
  background: var(--primary-color);
  width: 9vh;
  height: 9vh;
  display: flex;
  align-items: center;
}

.oq-profile-name {
  color: #6e7e90 !important;
}

.filter:hover,
.filter:active,
.filter:visited,
.filter:focus-within,
.filter:focus {
  color: var(--secondary-color);
  cursor: pointer;
  text-transform: uppercase;
  font-family: var(--oq-font-book);
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
  background-color: var(--primary-color);
  background-image: -webkit-linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-dark)
  );
  background-image: -moz-linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-dark)
  );
  background-image: -ms-linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-dark)
  );
  background-image: -o-linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-dark)
  );
  background-image: linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-dark)
  );
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=var(--primary-color), endColorstr=var(--primary-color-dark));
}

.oq-fa-title {
  font-size: 1.1rem !important;
  color: #8ea1b7;
}

.oq-fa-title-dashboard {
  font-size: 1rem !important;
  color: #8ea1b7;
}

.oq-info-title {
  color: #424242;
}

footer .oq-label {
  color: #8d8d8d;
}

footer .oq-line-height {
  line-height: 1rem;
}

footer .oq-eth-icon {
  font-size: 30px;
}

footer.oq-shadow {
  box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.075);
}

.no-margin {
  margin: 0px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt-1 {
  margin-top: 3vh !important;
}

.max-h-10-vh {
  max-height: 10vh;
  object-fit: contain;
}

.max-h-12-vh {
  max-height: 12vh;
  object-fit: contain;
}

.canceled-btn {
  position: absolute;
  width: 25%;
  right: 5%;
  top: 5%;
  background-color: white;
  height: 10%;
}

.canceled-text {
  color: var(--primary-color);
  font-size: 12px;
}

.btn-disabled {
  background-color: #7a7a7a;
  color: white;
}

.btn-disabled:hover {
  background-color: rgb(83, 83, 83);
  color: white;
}

.oq-obersvation {
  font-size: 0.8em;
  margin-right: 3vw;
}

#share-now {
  color: var(--primary-color);
}

.count-down {
  font-size: 30px;
}

.count-share {
  color: var(--primary-color);
}

.share-player {
  color: var(--primary-color);
}

.time-final {
  margin-top: 10px;
}

.action-slider {
  /* height: 25vw; */
}

.slider-image {
  /* height: 38vw; */
  /* object-fit: cover; */
}

.city {
  font-size: 16px;
}

.award {
  padding: 0 0 0 15px;
}

.right-info-action {
  height: 25vw;
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: space-around;
}

.share-text {
  font-size: 20px !important;
}

.hidden {
  display: none;
}

.teamLogo {
  width: 40px;
  margin: 10px 0 0;
  align-items: center;
}

.player-name {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1vh auto;
}

.player-details-margin-top {
  margin-top: 1vh !important;
}

.player-socials {
  width: 3vh;
  margin-right: 1vw;
}

.player-socials-display {
  display: flex;
  justify-content: center;
}

.question-wrapper {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.question-otpion {
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.8rem;
  background: #4d5156;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 4px;
}

.question-choosen-otpion {
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.8rem;
  background: var(--primary-color);
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 4px;
}

.option-text {
  margin: 0;
  background: transparent;
  color: white;
  font-size: 15px;
}

.m-bottom-4 {
  margin-bottom: 1.5rem !important;
}

.question-line {
  width: 100%;
  height: 1px;
  background: #bfc6cc;
  margin: 20px 0;
  margin-top: 3rem;
}

.gray-text {
  color: #8ea1b7;
  font-family: var(--oq-font-book);
  font-size: 14px;
}

.span-oq-margin {
  color: #8ea1b7;
  font-family: var(--oq-font-book);
  font-size: 14px;
  text-align: justify;
  margin-bottom: 5vh;
}

.my-profile-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  margin-right: -6.666667vh;
  object-fit: cover;
}

.trophy-wrapper {
  display: flex;
}

.parent {
  width: 100%;
  height: 100%;
}

.notebadge {
  position: absolute;
  height: 16px;
  width: 16px;
  top: 0;
  right: 40%;
  font-weight: 500;
  border-radius: 50%;
  background: red;
}

.voucherCard {
  background-color: var(--primary-color);
}

.btn-oq-share {
  color: var(--secondary-color);
  cursor: pointer;
  text-transform: uppercase;
  font-family: var(--oq-font-book);
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
  background-color: var(--primary-color);
  background-image: -webkit-linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-dark),
    rgb(0, 0, 0, 0.8)
  );
  background-image: -moz-linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-dark),
    rgb(0, 0, 0, 0.8)
  );
  background-image: -ms-linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-dark),
    rgb(0, 0, 0, 0.8)
  );
  background-image: -o-linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-dark),
    rgb(0, 0, 0, 0.8)
  );
  background-image: linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-dark),
    rgb(0, 0, 0, 0.8)
  );
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=var(--primary-color), endColorstr=var(--primary-color-dark));
}

.text-share-indication {
  animation: fade 5s;
  z-index: 10;
  height: fit-content;
  padding: 0;
}

.photo-share-indication {
  animation: fadePhoto 5s;
  z-index: 10;
  padding: 7px 0px 0px 0px;
}

.oq-height {
  height: 8vh;
  background-color: var(--primary-color);
  background-image: -webkit-linear-gradient(
    to left,
    var(--primary-color),
    var(--primary-color-dark),
    rgb(0, 0, 0, 0.8)
  );
  background-image: -moz-linear-gradient(
    to left,
    var(--primary-color),
    var(--primary-color-dark),
    rgb(0, 0, 0, 0.8)
  );
  background-image: -ms-linear-gradient(
    to left,
    var(--primary-color),
    var(--primary-color-dark),
    rgb(0, 0, 0, 0.8)
  );
  background-image: -o-linear-gradient(
    to left,
    var(--primary-color),
    var(--primary-color-dark),
    rgb(0, 0, 0, 0.8)
  );
  background-image: linear-gradient(
    to left,
    var(--primary-color),
    var(--primary-color-dark),
    rgb(0, 0, 0, 0.8)
  );
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=var(--primary-color), endColorstr=var(--primary-color-dark));
}

.oq-gradient-title {
  background: #9e9e9e;
  background-image: -webkit-linear-gradient(
    to left,
    #868686,
    rgb(192, 192, 192)
  );
  background-image: -moz-linear-gradient(to left, #868686, rgb(192, 192, 192));
  background-image: -ms-linear-gradient(to left, #868686, rgb(192, 192, 192));
  background-image: -o-linear-gradient(to left, #868686, rgb(192, 192, 192));
  background-image: linear-gradient(to left, #868686, rgb(192, 192, 192));
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=var(--primary-color), endColorstr=var(--primary-color-dark));
}

.img-action {
  padding-bottom: 26vh;
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
  max-height: 20rem;
}

.slide {
  width: 90%;
  /* height: 25rem; */
  max-height: 40rem;
  position: absolute;
  overflow: hidden;
}

.right {
  background-color: transparent;
}

.login-placeholder {
  width: 100%;
  font-family: var(--oq-font-book);
  font-size: 16px;
  height: 30px;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #696666;
  padding: 5px;
}

.form-div {
  background-color: hsla(0, 0%, 100%, 0.7);
  height: 376px;
  border-radius: 8px;
  /* margin: 6rem auto; */
  margin: 0 auto;
  padding-bottom: 15px;
}

.img-login-responsive {
  display: flex !important;
  max-height: 220px;
  width: 100%;
}

.centraliza {
  display: flex;
  justify-content: space-around;
}

.background-logo-client {
  /* position: fixed; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  /* height: 5rem; */
  width: 150px;
  margin: auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  opacity: 1;
  -webkit-animation: slideOut 8s ease !important;
  animation: slideOut 8s ease !important;
}

.center-steps {
  display: flex;
  align-items: center;
}

@media (max-width: 995px) {
  .title-mobile {
    text-align: center !important;
  }

  .center-steps {
    margin-bottom: 2rem;
    flex-direction: column;
  }

  .footer-top {
    margin-top: initial !important;
  }
}

.circle-steps {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  background-color: transparent;
  line-height: 50px;
  box-shadow: rgb(153 153 153) 2px 2px 5px;
  margin-bottom: 1rem;
}

.number-steps {
  margin: auto;
  font-size: 18px;
  font-weight: bold;
}

@media (min-width: 1200px) {
  .form-div {
    width: 375px;
  }
}

@media (max-width: 767.98px) {
  .img-login-responsive {
    display: flex !important;
    max-height: 220px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .img-action {
    padding-bottom: 75vh;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    max-height: 20rem;
  }
}

@media (max-width: 1199px) {
  .steps-responsive {
    line-height: initial;
    /* line-height: 2.2em; */
  }
}

/* @media (max-width: 1200px) {
  .steps-responsive {
    line-height: 1.2em;
  }
} */

@media (max-width: 515px) {
  .steps-responsive {
    /* line-height: 2.2em; */
    font-size: 15.6px !important;
  }
}

@media (max-width: 475px) {
  .steps-responsive {
    /* line-height: 1.4em; */
    font-size: 15.6px !important;
  }
}

.CookieConsent > div {
  text-align: center;
  justify-content: center;
}

.CookieConsent {
  border: 1px solid transparent;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  margin: 2rem 1rem;
}

@media (min-width: 1052px) {
  .CookieConsent {
    margin: 0 0 2rem 20rem;
  }
}

@media (max-width: 420px) {
  .CookieConsent {
    align-items: initial !important;
    /* bottom: 55px !important; */
  }

  .CookieConsent > div:first-child {
    flex: initial !important;
    margin: 1px !important;
    font-size: 15px !important;
    padding-top: 8px;
  }

  .CookieConsent > div > div {
    display: flex !important;
    align-items: center !important;
    justify-content: initial !important;
  }

  .CookieConsent button#rcc-confirm-button {
    padding: 4px !important;
    margin: 4px !important;
  }
}

@media (max-width: 320px) {
  .CookieConsent > div:first-child {
    font-size: 14px !important;
  }
}

#animation-title {
  animation: fader 5s;
  background-image: radial-gradient(
    circle,
    var(--primary-color),
    var(--primary-color-dark),
    rgb(37, 37, 37)
  );
}

#animation-title {
  display: block;
}

#animation-title h1 {
  position: static;
}

#animation-title h1 {
  animation: textFade 5s;
}

@keyframes fader {
  0%,
  40% {
    opacity: 1;
    visibility: visible;
  }

  70% {
    opacity: 0;
  }
}

@keyframes textFade {
  0%,
  10% {
    opacity: 0;
    visibility: hidden;
  }

  15%,
  70% {
    opacity: 1;
    visibility: visible;
  }

  80% {
    opacity: 0;
  }
}

@keyframes fadePhoto {
  0%,
  40% {
    opacity: 0;
  }

  50%,
  80% {
    opacity: 1;
  }
}

@keyframes fade {
  0%,
  35% {
    opacity: 0;
  }

  50%,
  80% {
    opacity: 1;
  }
}

@keyframes fadeMobile {
  0%,
  50% {
    height: 80vh;
  }

  80% {
    height: 31vh;
  }
}

@keyframes divMobile {
  0%,
  50% {
    height: 100vh;
  }

  80% {
    height: 31vh;
  }
}

@keyframes imgMobile {
  0%,
  50% {
    height: 100%;
  }

  80% {
    height: 100%;
  }
}

.text-chart {
  font-size: 14px !important;
}

.title-video-session-two {
  margin-bottom: 1rem;
}

.content-video-session-two {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.responsive-banner {
  padding-bottom: 12vh;
  margin-left: 0px;
  margin-right: 0px;
  width: 80%;
  max-height: 120rem;

  margin: 0 auto !important;
}

.modalForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.oq-permission-wrapper {
  display: flex;
  display: -webkit-flex;
  margin-top: 10%;
  justify-content: center;
}

.oq-permission-wrapper .oq-permission-content {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
}

.oq-permission-wrapper .oq-permission-content img {
  max-width: 60px;
}

.oq-permission-wrapper .oq-permission-content p {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.oq-permission-wrapper .oq-permission-content button {
  transition: ease 0.3s;
  outline: none;
  border: none;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: #fff;
  padding: 10px 20px;
}

.oq-permission-wrapper .oq-permission-content button:hover {
  filter: brightness(65%);
}

.custom-link {
  cursor: pointer;
  color: #1675e0;
}

.css-bg1rzq-control {
  position: relative;
  top: -10px;
  border: none !important;
}

.css-1szy77t-control {
  top: -10px;
  border: none !important;
  outline: none !important;
}
